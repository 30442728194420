import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ToWords } from 'to-words';
import GlobalKeyListener from '../../GlobalKeyListener';
import { useUser } from '../../Authdata';
import PaymentOut_invoice_one from './PaymentOut_invoice_one';
import Invoiceaddpanel from '../Invoiceaddpanel';
import LedgerPop from '../LedgerPop';

function PaymentOut(props) {
    const { auth } = useUser();
    const [voucherData, setVoucherData] = useState(null);
    const [ledger, setLedger] = useState({});
    const [ledgerData, setLedgerData] = useState([]);
    const [amount, setAmount] = useState(0);
    const [receiptControl, setReceiptControl] = useState("");
    const [newBill, setNewBill] = useState(true);
    const [billing, setBilling] = useState([]);
    const [dateLocal, setDateLocal] = useState("");
    const redirect = useNavigate();
    const [paymentNo, setPaymentNo] = useState(0);
    const [paymentData, setPaymentData] = useState([]);
    const printRef = useRef();
    const [confirmSave, setConfirmSave] = useState(true);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [ledgercheck,setLedgerChecker]=useState("");
    const [error, setError] = useState({
        ledger: "",
        amount: ""
    });
    const toWords = new ToWords();
    let params = useParams();

    const formatDate = (date) => {
        const d = new Date(date);
        const dd = String(d.getDate()).padStart(2, '0');
        const mm = String(d.getMonth() + 1).padStart(2, '0');
        const yyyy = d.getFullYear();
        return `${dd}/${mm}/${yyyy}`;
    };

    const defaultDate = () => {
        const date = new Date(auth.date);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params && params.slug) {
                    const res = await axios.get(`${props.url}/viewdata/${params.slug}/${auth.id}_pi_po/${auth.id}_stock/payment_out`);
                    if (res.data) {
                        const responsLedgerCr = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${res.data.ledger_cr}`);
                        const responsLedgerDr = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${res.data.ledger_dr}`);

                        const ledgerCrdata = responsLedgerCr.data;
                        const ledgerDrdata = responsLedgerDr.data;

                        setVoucherData(res.data);
                        setLedger({ id: ledgerDrdata.id, name: ledgerDrdata.name, under: ledgerDrdata.under });
                        setAmount(parseInt(res.data.total, 10));
                        setDateLocal(res.data.date);
                        setPaymentNo(res.data.pay_no);
                        setReceiptControl(JSON.stringify({ id: ledgerCrdata.id, name: ledgerCrdata.name }));
                    }
                }

                const ledgersResponse = await axios.get(`${props.url}/show_ledgers/${auth.id}_ledger`);
                setLedgerData(ledgersResponse.data);

                if (!params.slug && voucherData === null) {
                    getPaymentOut();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [newBill]);

    const getPaymentOut = async() => {
        let res=await axios.get(`${props.url}/payment-out/${auth.id}_pi_po`);
        if(res.data){
            setPaymentData(res.data);
            let invoiceNo = res.data.reduce((max, pay) => Math.max(max, pay.pay_no), 0);
            setPaymentNo(parseInt(invoiceNo) + 1);
        }
           
    };

    const ledgerIdentity = (val) => {
        let ledval = JSON.parse(val);
        setLedger(ledval);
        if (ledval.under === "purchase" || ledval.under === "sale") {
            $('#purchaseandsale').modal('show');
        }
    };

    async function paymentOutSave() {
        let validation = {};
        if (!ledger.name.trim()) {
            validation.ledger = "Particular is required";
        }
        if (amount === 0) {
            validation.amount = "Amount is required";
        }
        setError(validation);

        if (Object.keys(validation).length === 0) {
            let receiptLedger = JSON.parse(receiptControl);
            const data = {
                ledger_cr: receiptLedger.id,
                ledger_dr: ledger.id,
                total: amount,
                date: formatDate(dateLocal || new Date()),
                frm_id: auth.id,
                pay_no: paymentNo,
                slug: `payment-${paymentNo}`,
                bills: JSON.stringify({ bill: JSON.stringify(billing) }),
                under: 'payment_out'
            };
            let PaymentResponse = await axios.post(`${props.url}/insert_payment-out/${auth.id}_pi_po`, data);
            if (PaymentResponse.data) {
                if (Array.isArray(billing) && billing.length > 0) {
                    billing.forEach(async (bill) => {
                        const billdata = {
                            date: date,
                            particulars: bill.pro_id,
                            rate: bill.rate,
                            quantity: bill.quaty,
                            unit: bill.unit,
                            total: bill.Amout,
                            frm_id: auth.id,
                            bill_id: `payment-${paymentNo}`,
                            voucher: "payment_out"
                        };
                        await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                    });
                }else{
                    const billdata = {
                        date: date,
                        particulars: null,
                        rate: null,
                        quantity: null,
                        unit: null,
                        total: amount,
                        frm_id: auth.id,
                        bill_id: `payment-${paymentNo}`,
                        voucher: "payment_out"
                    };
                    await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                }

            }
            setConfirmSave(false);
            toast.success("Data saved successfully");
        }
    };

    const paymentOutUpdate = async () => {
        if (!Array.isArray(billing) || billing.length === 0) {
            toast.error("Voucher is Empty");
            return;
        }
        const receiptLedger = JSON.parse(receiptControl);
        const data = {
            ledger_cr: receiptLedger.id,
            ledger_dr: ledger.id,
            total: amount,
            date: formatDate(dateLocal || new Date()),
            frm_id: auth.id,
            pay_no: paymentNo,
            slug: `payment-${paymentNo}`,
            bills: JSON.stringify({ bill: JSON.stringify(billing) })
        };

        try {
            let res = await axios.put(`${props.url}/viewdataupdate/${auth.id}_pi_po/payment_out/${params.slug}`, data);
            if (res.data) {
                if (Array.isArray(billing) && billing.length > 0) {
                    if (!Array.isArray(billing) || billing.length === 0) {
                        toast.error("Voucher is Empty");
                        return;
                    }
                    const promises = billing.map(async (item) => {
                        if (!('stockid' in item)) {
                            let billdata = {
                                date: date,
                                particulars: item.pro_id,
                                rate: item.rate,
                                quantity: item.quaty,
                                unit: item.unit,
                                total: item.Amout,
                                frm_id: auth.id,
                                bill_id: `payment-${paymentNo}`,
                                voucher: "payment_out"
                            };
                            await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                        }
                    });
                    await Promise.all(promises);
                    redirect('/dashboard');
                } else {
                    const stock_id = JSON.parse(voucherData.stock_ids);
                    let stockid = stock_id[0];
                    const billdata = {
                        date: date,
                        particulars: null,
                        rate: null,
                        quantity: null,
                        unit: null,
                        total: amount,
                        frm_id: auth.id,
                        bill_id: `payment-${paymentNo}`,
                        voucher: "payment_out"
                    };
                    let res = await axios.put(`${url}/update_stock/${auth.id}_stock/${stockid}`, billdata);
                    if (res.data) {
                        redirect('/dashboard');
                    }
                }
            }
        } catch (error) {
            console.error("Error updating payment in data", error);
        }
    };

    const newPaymentIn = (val) => {
        if (val) {
            setConfirmSave(true);
            setAmount(val);
        }
    };

    const wordstotal = toWords.convert(amount);

    const printBill = useReactToPrint({
        content: () => printRef.current
    });

    const addBillData = (objs) => {
        setBilling(objs);
    };

    const allSet = () => {
        if (ledger.under === "purchase" || ledger.under === "sale") {
            const total = billing.reduce((sum, val) => sum + val.Amout, 0);
            setAmount(total);
        }
    };

    const newBillHandler = () => {
        setNewBill(!newBill);
        setBilling([]);
        setLedger({});
        setAmount(0);
        setReceiptControl("");
        getPaymentOut();
        setConfirmSave(true);
    };
    let date = "";
    let setdate_input = "";
    if (!dateLocal.includes('/') && dateLocal != "") {
        date = new Date(dateLocal);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;

    } else if (dateLocal.includes('/')) {
        date = dateLocal;
    }
    else {
        date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
        setdate_input = yyyy + '-' + mm + '-' + dd;
    }
    useEffect(() => {
        allSet();
    }, [deleteIndex, billing])
    const deleteItem = (index) => {
        setDeleteIndex(index)
    }
    const ReceiptControl=(val)=>{
        setReceiptControl(val);
        let control=JSON.parse(val);
        setLedgerChecker(control.name)
    }
    return (
        <div>
            <GlobalKeyListener onSave={paymentOutSave} onPrint={printBill} />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8 offset-md-2'>
                        <div className="card text-dark bg-light mb-3">
                            <div className="card-header text-center text-md-start">
                                <select className='mb-3 mb-md-0' onChange={(e) => ReceiptControl(e.target.value)} >
                                    {/* <option value={receiptControl}>{receiptControl}</option> */}
                                    <option value={""}>{"--Select--"}</option>
                                    {ledgerData && ledgerData.filter((led) => led.under === "cash" || led.under === "bank").map((objled) => (
                                        <option value={JSON.stringify({ id: objled.id, name: objled.name })} key={objled.name}>{objled.name}</option>
                                    ))}
                                </select>
                                <span className='offset-md-7 fw-bold d-md-flex d-md-inline justify-content-center'>
                                    DATE:<input type='date' onChange={(e) => setDateLocal(e.target.value)} min={defaultDate()} value={setdate_input || defaultDate()}></input>
                                </span>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-8 text-center text-md-start'>
                                        <h6>Particulars</h6>
                                        <select onChange={(e) => ledgerIdentity(e.target.value)} value={ledger}>
                                            {ledger.name ? <option>{ledger.name}</option> : <option>--Select--</option>}
                                            {ledgerData && ledgerData.map((res) => ledgercheck === res.name ? null : (
                                                <option value={JSON.stringify({ id: res.id, name: res.name, under: res.under })} key={res.name}>{res.name}</option>
                                            ))}
                                        </select>
                                        <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#addledger">
                                            +
                                        </button>
                                        <div>{error.ledger && <span className='text-danger'>{error.ledger}</span>}</div>
                                    </div>
                                    <div className='col-md-4 text-sm-center text-center'>
                                        <h6>Amount</h6>
                                        <input type='number' onChange={(e) => newPaymentIn(e.target.value)} value={amount}></input>
                                        <div>{error.amount && <span className='text-danger'>{error.amount}</span>}</div>
                                    </div>
                                </div>
                                {(ledger.under === "purchase" || ledger.under === "sale") &&
                                    <div className='row bg-white p-1 m-1 mt-2 border'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Particulars</th>
                                                    <th>Qty</th>
                                                    <th>Unit</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {billing && billing.map((val, idx) => (
                                                    <tr key={idx}>
                                                        <td>{val.particular}</td>
                                                        <td>{val.quaty}</td>
                                                        <td>{val.unit}</td>
                                                        <td>{val.rate}</td>
                                                        <td>{val.Amout}</td>
                                                        <td>
                                                            <button className='btn-close' onClick={() => deleteItem({ stock_id: val.stockid, item_id: val.item_id })}></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>}
                                <hr />
                                <div className='row'>
                                    {voucherData == null ?
                                        <div className='text-center'>
                                            <button className='btn btn-primary me-2' onClick={paymentOutSave}><i class="bi bi-floppy-fill"></i> Save</button>
                                            <button className='btn btn-primary me-2' onClick={printBill} id="printfun" disabled={confirmSave}><i class="bi bi-printer"></i> Print</button>
                                            <button className='btn btn-dark me-2' onClick={newBillHandler} disabled={confirmSave}><i class="bi bi-file-earmark-plus"></i> New</button>
                                        </div> :
                                        <div className='text-center'>
                                            <button className='btn btn-success' onClick={paymentOutUpdate}>Update</button>
                                            &nbsp;
                                            <button className='btn btn-primary me-2' onClick={printBill} id="printfun">Print</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-10 d-flex justify-content-center mt-5 mb-5 offset-md-1'>
                        <div>
                            <div className="card" ref={printRef}>
                                <PaymentOut_invoice_one paymentNo={paymentNo} date={date || formatDate(new Date())} ledger={ledger.name} amount={amount} wordstotal={wordstotal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="purchaseandsale" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Product Adder</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Invoiceaddpanel adbilldata={addBillData} url={props.url} localdate={null} vocheri={"payment"} settingcontrol={props.setting} billNo={null} newbill={newBill} debitornamesetup={null} setupledcranddr={null} voucherupdate={null} indireactanddireact={null} billcontroler={null} deletebillItem={deleteIndex} deleteItem={deleteItem} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={allSet} data-bs-dismiss="modal">AllSet</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addledger" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <LedgerPop url={props.url} onChildStateChange={newBillHandler} />
            </div>
            <ToastContainer />
        </div>
    );
}

export default PaymentOut;
