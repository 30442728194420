import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import RegisterPrint_invoice from './RegisterPrint_invoice';
function DebitNoteRegister(props) {
    const printref = useRef();
    const { auth } = useUser();
    const [debitnotedata, setDebitNoteData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [total, setTotal] = useState(0);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    useEffect(() => {
        axios.get(`${props.url}/debit-note/${auth.id}_s_p_cn_dn`).then((res) => {
            let result = res.data.sort(function (a, b) {
                a = a.date.split('/').reverse().join('');
                b = b.date.split('/').reverse().join('');
                return a > b ? 1 : a < b ? -1 : 0;
            });
            setDebitNoteData(result)
            setFilterdata(result);
        })
    }, [])
    useEffect(() => {
        filterData();
    }, [startDate, endDate]); // Re-run filterData whenever startDate or endDate changes

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filterData = () => {
        const filtered = filterdata.filter(item => {
            const itemDate = new Date(item.date.split('/').reverse().join('-'));
            const rangeStartDate = startDate ? new Date(startDate) : null;
            const rangeEndDate = endDate ? new Date(endDate) : null;

            if (rangeStartDate && itemDate < rangeStartDate) {
                return false;
            }

            if (rangeEndDate && itemDate > rangeEndDate) {
                return false;
            }

            return true;
        });

        setDebitNoteData(filtered); // Update filteredData state, not purchaseData
    };
    useEffect(() => {
        let total = 0;
        debitnotedata.forEach((data) => {
            total += parseInt(data.total);
        })
        setTotal(total);
    }, [debitnotedata])
    return (
        <div>
            <GlobalKeyListener />
            <div>
                <div className='container'>
                    <div className='row mb-4 '>
                        <div className='col-md-6'>
                            <h1 className='text-decoration-underline'>List Of All Debit Note Voucher</h1>

                        </div>
                        <div className='col-md-6 mt-3'>
                            <input type='date' value={startDate} onChange={handleStartDateChange}  ></input>
                            <label>to</label>
                            <input type='date' value={endDate} onChange={handleEndDateChange} ></input>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Vch No</th>
                                        <th scope="col">Debit</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        debitnotedata && debitnotedata.map((data) =>
                                            <tr>
                                                <th scope="row">{data.date}</th>
                                                <td>{data.ledger_dr}</td>
                                                <td>{data.debit_no}</td>
                                                <td>{data.total}</td>
                                                <td>
                                                    <Link className='btn btn-primary' to={`/debit-note/${data.slug}`}><i className='bi bi-pen'></i></Link>&nbsp;
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td className='fw-bold'>&#8377;{total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='row'>
                    <div className='text-center mt-2 '>
                        <button className='btn btn-primary' onClick={printbill} id="printfun"><i class="bi bi-printer"></i> Print</button>
                    </div>
                </div>
                </div>
                <div className='row d-none' >
                <div className='text-center' ref={printref}>
                    <RegisterPrint_invoice saledata={debitnotedata} total={total} register={"Debit Note"} filterdate={startDate && endDate ?`${startDate} to ${endDate}`:null}/>
                </div>
            </div>
            </div>
        </div>
    )
}

export default DebitNoteRegister