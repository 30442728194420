import React, { useEffect, useRef, useState } from 'react';
import GlobalKeyListener from '../../GlobalKeyListener';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../Authdata';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import DashboardCard_invoice from './DashboardCard_invoice';

function DashboardCards(props) {
    const params = useParams();
    const { auth, reloadpage } = useUser();
    const [data, setData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        FinderData();
        reloadpage()
    }, []);

    const FinderData = async () => {
        try {
            const creditorLedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/${params.card}`);
            const creditorLedgerData = creditorLedgerResponse && creditorLedgerResponse.data;
            let allData = [];
            let hasOpeningBalance = false;
            for (const element of creditorLedgerData) {
                const whatDataResponse = await axios.get(`${props.url}/finder/${auth.id}/${element.id}/`);
                const whatData = sortDataByDate(whatDataResponse.data);
                let data = [];
                if (!hasOpeningBalance) {
                    data.push({
                        date: null,
                        particular: "Opening Balance",
                        vch_no: null,
                        credit: null,
                        debit: null,
                        under: null,
                        ledger_cr: null,
                        ledger_dr: null,
                        balance: 0,
                        name: null
                    })
                    hasOpeningBalance = true;
                }

                whatData.forEach((obj) => {
                    if (obj.under === "journal") {
                        let total = JSON.parse(obj.total);
                        data.push({
                            date: obj.date,
                            particular: obj.ledger_cr === element.id ? obj.ledger_dr : obj.ledger_cr,
                            vch_no: obj.slug,
                            credit: total[element.id].credit,
                            debit: total[element.id].debit,
                            under: obj.under,
                            ledger_cr: obj.ledger_cr,
                            ledger_dr: obj.ledger_dr,
                            balance: 0,
                            name: element.name
                        });
                    } else {
                        data.push({
                            date: obj.date,
                            particular: obj.ledger_cr === element.name ? obj.ledger_dr : obj.ledger_cr,
                            vch_no: obj.slug,
                            credit: obj.ledger_cr === element.name ? obj.total : null,
                            debit: obj.ledger_dr === element.name ? obj.total : null,
                            under: obj.under,
                            ledger_cr: obj.ledger_cr,
                            ledger_dr: obj.ledger_dr,
                            balance: 0,
                            name: element.name
                        });
                    }
                });

                allData = allData.concat(data);
            }

            let runningBalance = 0;
            let totalCredit = 0;
            let totalDebit = 0;

            allData.forEach((element, index) => {
                if (index === 0) {
                    element.balance = runningBalance;
                } else {
                    if (element.debit) {
                        runningBalance += parseFloat(element.debit);
                        totalDebit += parseFloat(element.debit);
                    }
                    if (element.credit) {
                        runningBalance -= parseFloat(element.credit);
                        totalCredit += parseFloat(element.credit);
                    }
                    element.balance = runningBalance;
                }
            });

            allData.push({
                date: null,
                particular: "Closing Balance",
                vch_no: null,
                credit: totalCredit,
                debit: totalDebit,
                under: null,
                ledger_cr: null,
                ledger_dr: null,
                balance: runningBalance,
                name: null
            });


            setData(allData);
            setFilterdata(allData);
        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    };

    const printref = useRef();
    const printbill = useReactToPrint({
        content: () => printref.current
    });

    useEffect(() => {
        filterData();
    }, [startDate, endDate]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filterData = () => {
        try {
            let openingBalance = 0;
            filterdata.forEach(item => {
                if (item.date !== null) {
                    const itemDate = new Date(item.date.split('/').reverse().join('-'));
                    const rangeStartDate = startDate ? new Date(startDate) : null;
                    if (rangeStartDate && itemDate < rangeStartDate) {
                        if (item.debit) {
                            openingBalance += parseFloat(item.debit);
                        }
                        if (item.credit) {
                            openingBalance -= parseFloat(item.credit);
                        }
                    }
                }
            });

            // Filter data based on the provided date range
            const filtered = filterdata.filter(item => {
                if (item.date === null) return true; // Include the opening balance record
                const itemDate = new Date(item.date.split('/').reverse().join('-'));
                const rangeStartDate = startDate ? new Date(startDate) : null;
                const rangeEndDate = endDate ? new Date(endDate) : null;
                if (rangeStartDate && itemDate < rangeStartDate) {
                    return false;
                }
                if (rangeEndDate && itemDate > rangeEndDate) {
                    return false;
                }
                return true;
            });

            // Initialize the running balance with the calculated opening balance
            let runningBalance = openingBalance;
            let totalCredit = 0;
            let totalDebit = 0;

            // Create a new array to store the filtered data with balances
            const resultWithBalances = [
                {
                    date: null,
                    particular: "Opening Balance",
                    debit: null,
                    credit: null,
                    vch_no: null,
                    balance: runningBalance,
                    under: null,
                    name: null,
                    ledger_cr: null,
                    ledger_dr: null,
                }
            ];

            filtered.forEach(item => {
                if (item.debit && item.date != null) {
                    runningBalance += parseFloat(item.debit);
                    totalDebit += parseFloat(item.debit);
                }
                if (item.credit && item.date != null) {
                    runningBalance -= parseFloat(item.credit);
                    totalCredit += parseFloat(item.credit);
                }
                if (item.date != null) {
                    resultWithBalances.push({
                        ...item,
                        balance: runningBalance,
                    });
                }

            });

            // Add closing balance object to the result array
            resultWithBalances.push({
                date: null,
                particular: "Closing Balance",
                debit: totalDebit,
                credit: totalCredit,
                vch_no: null,
                balance: runningBalance,
                under: null,
                name: null,
                ledger_cr: null,
                ledger_dr: null

            });
            setData(resultWithBalances);
        } catch (error) {
            console.error('Error filtering data:', error);
        }
    };

    const sortDataByDate = (data) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.date.split('/').reverse().join('-'));
            const dateB = new Date(b.date.split('/').reverse().join('-'));
            return dateA - dateB;
        });
    };

    let total = 0;
    data && data.forEach((info) => {
        if (info.under === "purchase" || info.under === "creditnote" || info.under === "payment_out" || (info.under === "journal" && info.ledger_cr === info.name)) {
            total -= parseInt(info.amount);
        } else if (info.under === "sale" || info.under === "debitnote" || info.under === "payment_in" || (info.under === "journal" && info.ledger_dr === info.name)) {
            total += parseInt(info.amount);
        }
    });

    return (
        <div>
            <GlobalKeyListener />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <h1 className='text-decoration-underline'>{params.card.toUpperCase()}</h1>
                    </div>
                    <div className='col-md-12 mt-3 text-center'>
                        <input type='date' value={startDate} onChange={handleStartDateChange} />
                        <label>to</label>
                        <input type='date' value={endDate} onChange={handleEndDateChange} />
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Particulars</th>
                            <th scope="col">Vch No</th>
                            <th scope="col">Credit</th>
                            <th scope="col">Debit</th>
                            <th scope='col'>Balance</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((info, index) => (
                            <tr key={index}>
                                <th scope="row">{info.date}</th>
                                <td>{info.particular}</td>
                                <td>{info.vch_no}</td>
                                <td>{info.credit && `₹${info.credit}`}</td>
                                <td>{info.debit && `₹${info.debit}`}</td>
                                <td>{info.balance && `₹${info.balance}`}</td>
                                <td>
                                    {info.particular === "Opening Balance" || info.particular === "Closing Balance" ? null : (
                                        <Link to={`/${info.under === "payment_in" ? "payment-in" : info.under === "payment_out" ? "payment-out" : info.under === "creditnote" ? "credit-note" : info.under === "debitnote" ? "debit-note" : info.under}/${info.vch_no}`} className='btn btn-primary'>
                                            <i className='bi bi-pen'></i>
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <button className='btn btn-primary' onClick={printbill} id="printfun"><i class="bi bi-printer"></i> Print</button>
                    </div>
                </div>
                <div className='d-none'>
                    <div ref={printref}>
                        <DashboardCard_invoice url={props.url} card={params && params.card} data={data} total={total} date={startDate && endDate ? `${startDate} to ${endDate}` : null} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardCards;
