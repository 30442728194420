import React, { useEffect, useState } from 'react'
import { useUser } from '../../Authdata'
function DashboardCard_invoice({url,card,data,total,date}) {
    let {auth}=useUser();
    return (
        <div>
                <div className='row'>
                    <div className='col-12 text-center'>
                    <img src={`${url}/upload/${auth && auth.logo}`} className='text-center' height={"80px"}></img>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='text-decoration-underline'>{auth.frm_name}</h1>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='text-decoration-underline'>{card.toUpperCase()}</h1>
                    </div>
                    <div className='col-md-12 mt-3 text-center'>
                        <span>{date?date:null}</span>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Particulars</th>
                            <th scope="col">Vch No</th>
                            <th scope="col">Credit</th>
                            <th scope="col">Debit</th>
                            <th scope="col">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((info) =>
                                <tr>
                                    <th scope="row">{info.date}</th>
                                    <td>{info.particular}</td>
                                    {/* <td>{info.under == params.card ? info.ledger_cr : info.ledger_dr}</td> */}
                                    <td>{info.vch_no}</td>
                                    <td>{info.credit && `₹${info.credit}`}</td>
                                    <td>{info.debit && `₹${info.debit}`}</td>
                                    <td>{info.balance && `₹${info.balance}`}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
        </div>
    )
}

export default DashboardCard_invoice