import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import SharePDFButton from '../SharePDFButton';
import BasicDocument from '../BasicDocument';
import { ToWords } from 'to-words';
import LedgerPop from '../LedgerPop';
import Invoiceaddpanel from '../Invoiceaddpanel';
import GlobalKeyListener from '../../GlobalKeyListener';
import Invoice_one from './Invoice_one';
import { useUser } from '../../Authdata';
import { decryptData, encryptData } from '../../encryptionUtils';
import Invoice_two from './Invoice_two';
import Sale_Invoice from './Sale_Invoice';
import Sale_invoice_two from './Sale_invoice_two';
import { Modal, Button } from 'react-bootstrap';
function Sale(props) {
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const { auth } = useUser();
    const pdfUrl = '';
    const [vocherdata, setVocherData] = useState(null);
    const [reloadpage, setReloadpage] = useState(0);
    const printref = useRef();
    const [bosNo, setBosNo] = useState(0);
    const [billing, setBilling] = useState([]);
    const [BillControler, setBillControler] = useState("cash");
    const [debtarname, SetDebtarname] = useState({});
    const redirect = useNavigate();
    const [datelocal, setDatelocal] = useState("");
    const [confirmsave, setConfirmSave] = useState(false);
    const toWords = new ToWords();
    const [pdfdata, setPdfdata] = useState({});
    const [Newbill, setNewBill] = useState(false)
    const [sale, setSale] = useState("");
    const [alldataIndireact_direact, setAlldataIndireact_direact] = useState([]);
    const [deletebillItem, setDeleteBillItem] = useState(null);
    const [ledgerdata, setLedgerData] = useState([]);
    // const [invoicedata, setInvoiceData] = useState([]);
    let params = useParams();
    var date = "";
    useEffect(() => {
        setSetting(JSON.parse(localStorage.getItem('setting')))
    }, [props.setting]);
    useEffect(() => {
        const callLedgerdata = async () => {
            let res = await axios.get(`${props.url}/show_ledgers/${auth.id}_ledger`);
            setLedgerData(res.data);
        }
        callLedgerdata();
    }, [])
    const UpdateVoucher = (objs) => {
        setVocherData(objs);
    }
    let AddBilledata = (objs) => {
        setBilling(objs);
        setNewBill(false)
    }
    let localdateset = (d) => {
        setDatelocal(d);
    }
    const debitornamesetup = (name) => {
        if (name) {
            let obj = JSON.parse(name);
            SetDebtarname(obj && obj);
        }

    }
    let total = 0;
    let totalamount = 0;
    billing.forEach((da) => {
        total += da.Amout;
    })
    let discount = 0;
    alldataIndireact_direact.forEach((v) => {
        if (v.percentage != "") {
            discount = total * (v.percentage / 100)
            totalamount = total - Math.round(discount)

        } else {
            totalamount = total - v.amount
        }
    })
    let wordstotal = toWords.convert(totalamount != 0 ? totalamount : total);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    const SaveBilldata = async () => {
        if (!billing || billing.length === 0) {
            toast.info("Bill is empty");
            return;
        }
    
        try {
            // Convert billing data to JSON
            let bill = JSON.stringify(billing);
            let indireact_and_direact = JSON.stringify(alldataIndireact_direact);
    
            let ledger_dr = "";
    
            // Determine ledger_dr
            if (debtarname && debtarname.id && BillControler === "debtor") {
                ledger_dr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_dr = cashLedger.id;
                }
            }
    
            if (!ledger_dr) {
                throw new Error("Ledger information is missing");
            }
    
            // Create data payload for sale
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill, indireact_and_direact }),
                total: totalamount !== 0 ? totalamount : total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_dr,
                slug: `sale-${bosNo}`,
                ledger_cr: sale.id,
                under: "sale"
            };
    
            // Send sale data to the server
            const saleResponse = await axios.post(`${props.url}/insert_sale/${auth.id}_s_p_cn_dn`, data);
    
            if (saleResponse.data) {
                for (const billItem of billing) {
                    let billdata = {
                        date: datelocal,
                        particulars: billItem.pro_id,
                        rate: billItem.rate,
                        quantity: billItem.quaty,
                        unit: billItem.unit,
                        total: billItem.Amout,
                        frm_id: auth.id,
                        bill_id: `sale-${bosNo}`,
                        voucher: "sale"
                    };
                    await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                }
                setConfirmSave(true);
                setBilling([]);
                toast.success("Save");
               
            } else {
                throw new Error("Failed to save sale data");
            }
        } catch (error) {
            console.error("Error saving bill data:", error);
            if (toast) {
                toast.error("There was an error saving the bill. Please try again.");
            } else {
                console.warn("Toast object is not defined");
            }
        }
    };

    const NEW = () => {
        setNewBill(true)
        SetDebtarname({})
        // LedgerData();
        // setReloadpage(1 + reloadpage)
        setConfirmSave(false)
    }
    let UpdateBilldata = async () => {
        try {
            let bill = JSON.stringify(billing);
            let indireact_and_direact = JSON.stringify(alldataIndireact_direact);
            let ledger_dr = "";

            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_dr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_dr = cashLedger.id;
                }
            }
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill, indireact_and_direact }),
                total: totalamount != 0 ? totalamount : total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_dr: ledger_dr,
                ledger_cr: sale.id,
                slug: `sale-${bosNo}`,
                under: "sale"
            };

            let res = await axios.put(`${props.url}/viewdataupdate/${auth.id}_s_p_cn_dn/sale/${params.slug}`, data);

            if (res.data) {
                // Collect promises for batch processing
                const promises = billing.map(async (item) => {
                    if (!('stockid' in item)) {
                        let billdata = {
                            date: datelocal,
                            particulars: item.pro_id,
                            rate: item.rate,
                            quantity: item.quaty,
                            unit: item.unit,
                            total: item.Amout,
                            frm_id: auth.id,
                            bill_id: `sale-${bosNo}`,
                            voucher: "sale"
                        };
                        console.log(billdata);
                        await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                    }
                });
                await Promise.all(promises);
                // Redirect after all operations are done
                redirect('/dashboard');
            }
        } catch (error) {
            console.error("Error updating bill data:", error);
            // You can add further error handling here if needed, like showing an alert to the user
        }
    }


    const Indireact_and_direact = (v) => {
        console.log(v);
        setAlldataIndireact_direact(v);
    }
    const billcontroler = (val) => {
        setBillControler(val)
    }
    const CrandDr = (val) => {
        if (val) {
            let obj = JSON.parse(val);
            setSale(obj && obj);
        }
        // setSale(val)
    }
    const billofsplly = (val) => {
        // if(setting.bosno=="manual"){
        setBosNo(val.bosno);
        // alert(val)
        // }
    }
    const handleLedger = () => {
        // Logic to handle ledger popup
        return <LedgerPop url={props.url} />;
    };
    const deleteItem = (val) => {
        setDeleteBillItem(val);
    }
    return (
        <div>
            <GlobalKeyListener onSave={SaveBilldata} onPrint={printbill} onNew={NEW} onledger={handleLedger} />
            <div>
            </div>

            {/* <div className='container-fluid'> */}

            <div className='row text-center '>
                <div className='col-md-2 border'>
                    {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addbilling">
                        Add Billing
                    </button>
                    <div class="modal fade" id="addbilling" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Add Bill</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body"> */}
                                <Invoiceaddpanel adbilldata={AddBilledata} url={props.url} localdate={localdateset} vocheri={"sale"} settingcontrol={props.setting} billNo={billofsplly} newbill={Newbill} debitornamesetup={debitornamesetup} voucherupdate={UpdateVoucher} indireactanddireact={Indireact_and_direact} billcontroler={billcontroler} setupledcranddr={CrandDr} returnBillno={null} deletebillItem={deletebillItem} deleteItem={deleteItem}/>
                                {/* </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className='col-md-2 border rounded-start '>
                    <Invoiceaddpanel adbilldata={AddBilledata} url={props.url} localdate={localdateset} vocheri={"sale"} settingcontrol={props.setting} billNo={billofsplly} newbill={Newbill} debitornamesetup={debitornamesetup} voucherupdate={UpdateVoucher} indireactanddireact={Indireact_and_direact} billcontroler={billcontroler} setupledcranddr={CrandDr} returnBillno={null} deletebillItem={deletebillItem} deleteItem={deleteItem}/>
                </div> */}
                <div className={setting.invoice == "invoice-1"?'col-md-10 d-md-flex justify-content-center mt-md-5 mb-md-5' : "col-md-10"} >
                    <div>
                        <div ref={printref} className='text-center me-5 mx-5 '>
                            {
                                setting.invoice == "invoice-1" ?
                                    <Invoice_one url={props.url} bosNo={bosNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} BillControler={BillControler} />
                                    : <Invoice_two url={props.url} bosNo={bosNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} BillControler={BillControler} invoicdesc={setting.invoicedescription}/>
                            }
                        </div>
                        {
                            vocherdata ? <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid ">
                                    <button onClick={UpdateBilldata} className='btn btn-success' >Update</button>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" >Print</button>
                                </div>
                            </nav> : <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid ">
                                    <button onClick={SaveBilldata} className='btn btn-primary' ><i class="bi bi-floppy-fill"></i> Save</button>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" disabled={confirmsave ? false : true}><i class="bi bi-printer"></i> Print</button>
                                    <button className='btn btn-dark' onClick={() => NEW()} disabled={confirmsave ? false : true}><i class="bi bi-file-earmark-plus"></i>    New</button>

                                    {/* <SharePDFButton url={props.url} data={pdfdata} /> */}
                                </div>
                            </nav>

                        }
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
        // </div >
    )
}

export default Sale