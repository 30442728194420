import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {toast } from 'react-toastify';
import { useUser } from '../Authdata';
function LedgerPop({ url, onChildStateChange }) {
    const { auth } = useUser();
    const [name, setName] = useState(null);
    const [phoneNo, setPhoneNo] = useState(null);
    const [address, setAddress] = useState(null);
    const [debtordata, setDebtorData] = useState([]);
    const [bankname, setBankName] = useState(null);
    const [account_no, setAccountNo] = useState(null);
    const [othername, setOtherName] = useState(null);
    const [IFSC, setIFSC] = useState(null);
    const [selectInputControl, setSelectInputControl] = useState("debtor");
    useEffect(() => {
        LedgerData();
    }, [])
    const LedgerData =async () => {
        let response=await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
        setDebtorData(response.data);
    }
    const AddDebtor = async () => {
        let data = {};
        const startsWithNumber = /^\d/;
        
        // Check if the name starts with a number
        if ((name && startsWithNumber.test(name)) || (bankname && startsWithNumber.test(bankname)) || (othername && startsWithNumber.test(othername))) {
            toast.error("Name cannot start with a number");
            return;
        }

        if (name && phoneNo && address) {
            let ledgername = name.charAt(0).toUpperCase() + name.slice(1);
            let ledgeraddress = address.charAt(0).toUpperCase() + address.slice(1);
            data = { name: ledgername, phoneno: phoneNo, address: ledgeraddress, frm_id: auth.id, under: selectInputControl };
        } else if (bankname && account_no && IFSC) {
            let ledgerbankname = bankname.charAt(0).toUpperCase() + bankname.slice(1);
            data = { name: ledgerbankname, bankname: bankname, account_no: account_no, ifsc: IFSC, frm_id: auth.id, under: selectInputControl };
        } else if (othername && selectInputControl !== "debtor" && selectInputControl !== "bank") {
            let ledgerothername = othername.charAt(0).toUpperCase() + othername.slice(1);
            data = { name: ledgerothername, frm_id: auth.id, under: selectInputControl };
        } else {
            toast.info("Fields are empty");
            return;
        }

        try {
            let response = await axios.post(`${url}/insert_ledger/${auth.id}_ledger`, data);
            if(response.data){
                // if (response.data !== "DuplicateEntry") {
                    onChildStateChange && onChildStateChange();
                    LedgerData();
                    setName("");
                    setPhoneNo("");
                    setAddress("");
                    setBankName("");
                    setAccountNo("");
                    setIFSC("");
                    setOtherName("");
                    toast.success("Debtor added successfully");
                // } else {
                //     toast.error("Ledger already exists");
                // }
            }
            
        } catch (error) {
            toast.error("Error adding debtor");
        }
    };
    const inputconteoldata = (val) => {
        if (val == "debtor") {
            setBankName("");
            setAccountNo("")
            setIFSC("")
            setOtherName("")
            setSelectInputControl(val)
        } else if (val == "bank") {
            setName("");
            setAddress("")
            setPhoneNo("")
            setOtherName("")
            setSelectInputControl(val)
        } else {
            setBankName("");
            setAccountNo("")
            setIFSC("")
            setName("");
            setAddress("")
            setPhoneNo("")
            setSelectInputControl(val)
        }

    }
    return (
        <div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <select class="modal-title form-select" onChange={(e) => inputconteoldata(e.target.value)}>
                            <option value={'debtor'} className='text-uppercase'>DEBTOR</option>
                            <option value={'bank'} className='text-uppercase'>BANK</option>
                            <option value={'current-asset'} className='text-uppercase'>CURRENT ASSET</option>
                            <option value={'current-libility'} className='text-uppercase'>CURRENT LIABILITY</option>
                            <option value={'fixed-assest'} className='text-uppercase'>FIXED ASSET</option>
                            <option value={'sale'} className='text-uppercase'>sale</option>
                            <option value={'purchase'} className='text-uppercase'>PURCHASE</option>
                            <option value={'creditor'} className='text-uppercase'>Creditor</option>
                            <option value={'capital-account'} className='text-uppercase'>CAPITAL ACCOUNT</option>
                            <option value={'indirect-income'} className='text-uppercase'>Indirect Income</option>
                            <option value={'indirect-expenses'} className='text-uppercase'>Indirect expenses</option>
                            <option value={'cash'} className='text-uppercase'>Cash</option>
                            <option value={'investments'} className='text-uppercase'>Investments</option>
                            <option value={'direct-expenses'} className='text-uppercase'>Direct expenses</option>
                            <option value={'direct-income'} className='text-uppercase'>Direct Income</option>
                        </select>
                        {/* <h5 class="modal-title" id="exampleModalLabel">DEBTOR</h5> */}
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {
                            selectInputControl == "debtor" || selectInputControl == "creditor"? <>
                                <div class="mb-3">
                                    <label for="recipient-name" class="form-label">Name:</label>
                                    <input type="text" class="form-control" value={name} onChange={(e) => setName(e.target.value)} ></input>
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="form-label">PhoneNo:</label>
                                    <input type="text" class="form-control" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} maxLength="10"></input>
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="form-label">Address:</label>
                                    <textarea class="form-control" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                </div></>
                                : selectInputControl == "bank" ? <>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">Bank Name:</label>
                                        <input type="text" class="form-control" value={bankname} onChange={(e) => setBankName(e.target.value)}></input>
                                    </div>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">Account No:</label>
                                        <input type="number" class="form-control" value={account_no} onChange={(e) => setAccountNo(e.target.value)}></input>
                                    </div>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">IFSC:</label>
                                        <input type="text" class="form-control" value={IFSC} onChange={(e) => setIFSC(e.target.value)}></input>
                                    </div>
                                </> : selectInputControl == "current-asset"
                                    || selectInputControl == "current-libility"
                                    || selectInputControl == "fixed-assest"
                                    || selectInputControl == "purchase"
                                    || selectInputControl == "sale"
                                    || selectInputControl == "capital-account"
                                    || selectInputControl == "indirect-income"
                                    || selectInputControl == "indirect-expenses"
                                    || selectInputControl == "investments"
                                    || selectInputControl == "cash"
                                    || selectInputControl == "direct-expenses"
                                    || selectInputControl == "direct-income"

                                    ? <>
                                        <div class="mb-3">
                                            <label for="recipient-name" class="form-label">Name:</label>
                                            <input type="text" class="form-control" value={othername} onChange={(e) => setOtherName(e.target.value)}></input>
                                        </div>
                                    </> : null

                        }
                    </div>
                    <div class="modal-footer">
                        <div className="d-grid gap-2 col-6 mx-auto">
                            <button className="btn btn-primary" type="button" onClick={() => AddDebtor()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LedgerPop