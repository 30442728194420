import React from 'react'
import { useUser } from '../Authdata'
function RegisterPrint_invoice({saledata,total,register,filterdate}) {
    const {auth}=useUser();
    return (
        <div>
            <div className='row text-center'>
                <h1>{auth.hotel_name}</h1>
            </div>
            <div className='row text-center'>
                <div>{auth.address}</div>
            </div>
            <div className='row text-center'>
                <h2>{`${register} Register`}</h2>
                <p>{filterdate}</p>
            </div> 
        <div className='row'>
                <div className='col-12'>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">date</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Vch No</th>
                                <th scope="col">Debit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                    saledata && saledata.map((data) =>
                                        <tr>
                                            <th scope="row">{data.date}</th>
                                            <td>{data.ledger_dr}</td>
                                            <td>{data.bos_no}</td>
                                            <td>&#8377;{data.total}</td>
                                        </tr>
                                    )

                                }
                            <tr>
                                <td></td>
                                <td></td>
                                <td className='fw-bold'>Total</td>
                                <td className='fw-bold'>&#8377;{total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default RegisterPrint_invoice