import React, { useEffect, useState } from 'react'
import { useUser } from '../../Authdata';
function Invoice_one({url,bosNo,datelocal,billing,alldataIndireact_direact,discount,total,wordstotal,debtarname,totalamount,deleteIndex,BillControler}) {
    const { auth } = useUser();
    const [deleteIndexno,setDeleteIndexNo]=useState("");
    deleteIndex(deleteIndexno);
    return (
        <div >
            <div class="card me-5 mx-5" >
                {/* <div className='bg-dark text-white text-uppercase text-center'>Invoice</div> */}
                <div class="card-body mx-4">
                    <div class="container">
                        <div className='row'>
                            <div className='offset-5  col-2'>
                                <img src={`${url}/upload/${auth && auth.logo}`} className='text-center' height={"80px"}></img>
                            </div>
                        </div>
                        <p class="text-center" style={{ fontSize: "30px" }}>{auth.hotel_name}</p>
                        <p class="text-center" >{auth.address}</p>
                        <div class="row">
                            <table>
                                <tr className=''>
                                    <th>BOS No:{bosNo}</th>
                                    <th >Date:{datelocal}</th>
                                </tr>
                                <tr>
                                    <td><b>{debtarname.name}</b></td>
                                </tr>
                            </table>
                            <hr></hr>
                            <table className='table'>
                                <tr>
                                    <th >Particulars</th>
                                    <th >Qty</th>
                                    <th >Unit</th>
                                    <th >Rate</th>

                                    <th >Amount</th>
                                    <th ></th>
                                </tr>
                                {
                                    billing && billing.map((item,index) =>
                                        <tr >
                                            <td >{item.particular}</td>
                                            <td >{item.quaty}</td>
                                            <td >{item.unit}</td>
                                            <td >{item.rate}</td>
                                            <td >{item.Amout}</td>
                                            <td >
                                                <button className='btn-close' onClick={() => setDeleteIndexNo({pro_id:item.pro_id,index,item_id:item.item_id})}></button>
                                                {/* <input type='radio' name='deleteitem' onClick={(e) => setDeleteIndexNo(e.target.value)} value={index}></input> */}
                                            </td>
                                        </tr>

                                    )

                                }
                                {
                                    totalamount != 0 ?
                                        <>
                                            <tr className="middle-line">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{total}</td>
                                            </tr>
                                        </>
                                        : null
                                }
                                {
                                    alldataIndireact_direact && alldataIndireact_direact.map((item) =>
                                        <tr>
                                            <td >{item.particular}</td>
                                            <td ></td>
                                            <td ></td>
                                            <td >{item.percentage != "" ? `${item.percentage}%` : null}</td>
                                            <td >{item.percentage != "" ? discount : item.amount}</td>
                                        </tr>
                                    )
                                }
                            </table>
                            <hr style={{ border: "2px solid black" }}></hr>
                        </div>


                        <div className='row'>
                            <table>
                                <tr >
                                    <th class="float-start fw-normal">Total Amount in Words:&nbsp;<span className='fw-bold'>Rupees&nbsp;{wordstotal}</span></th>
                                    <th class="float-end fw-bold ">Total:&#8377;{totalamount != 0 ? totalamount : total}</th>
                                </tr>
                            </table>
                            <hr style={{ border: "2px solid black" }}></hr>
                        </div>
                        <div className='row'>
                            <div className='col'>FSSAI NO:</div>
                            <div className='col'>{auth.fssai}</div>
                        </div>
                        <div class="text-center" style={{ marginTop: "20px" }}>
                            <u class="text-dark">Thank You Visit Again </u>
                            <p >Please Check The Bill Before Making The Payment
                                No Complaints Will Be Entertain Thereafter
                            </p>
                            {/* <p class="text-start">SRI GANESHAY NAMAH</p> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice_one