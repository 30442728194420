import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToWords } from 'to-words';
import LedgerPop from '../LedgerPop';
import Invoiceaddpanel from '../Invoiceaddpanel';
import GlobalKeyListener from '../../GlobalKeyListener';
import { useUser } from '../../Authdata';
import Creditnote_invoice_one from './Creditnote_invoice_one';
import Creditnote_invoice_two from './Creditnote_invoice_two';
function CreditNote(props) {
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const { auth } = useUser();
    const [reloadpage, setReloadpage] = useState(0);
    const printref = useRef();
    const [datelocal, setDatelocal] = useState("");
    const [data, setData] = useState([]);
    const [invoicedata, setInvoiceData] = useState([]);
    const [bosNo, setBosNo] = useState(0);
    const [creNo, setCreNo] = useState(0);
    // const [particular, setParticular] = useState("");
    // const [rate, setRate] = useState(0);
    // const [qty, setQty] = useState([]);
    // const [quaty, setQuaty] = useState(0);
    // const [Amout, setAmoutes] = useState(0);
    const [billing, setBilling] = useState([]);
    const [BillControler, setBillControler] = useState("cash");
    const [debtordata, setDebtorData] = useState([]);
    const [debtarname, SetDebtarname] = useState({});
    const [debtorname, setDebtorName] = useState([]);
    const redirect = useNavigate();
    const [confirmsave, setConfirmSave] = useState(false);
    const toWords = new ToWords();
    const [vocherdata,setVocherData]=useState(null);
    const [newbill,setNewBill]=useState(false)
    const [creditnote,setCreditnote]=useState("");
    const [alldataIndireact_direact,setAlldataIndireact_direact]=useState([]);
    const [deleteIndex,setDeleteIndex]=useState(null);
    const [ledgerdata,setLedgerData]=useState([]);
    let params = useParams();
    useEffect(() => {
        setSetting(JSON.parse(localStorage.getItem('setting')))
    }, [props.setting]);
    useEffect(()=>{
        const callLedgerdata=async()=>{
            let res=await axios.get(`${props.url}/show_ledgers/${auth.id}_ledger`);
            // console.log(res.data);
            setLedgerData(res.data);
        }
        callLedgerdata();
    },[])
    var date = "";
    const billofsplly=(bn)=>{
        setCreNo(bn.creno);
        // setBosNo(bn.bosno);
    }
    let AddBilledata = (objs) => {
        // console.log(objs);
        // let billdata = [...billing, objs];
        setBilling(objs);
        setNewBill(false);
        // setBilling(objs);
    }
    let localdateset=(d)=>{
        setDatelocal(d);
    }
    const newBill = () => {
        setNewBill(true)
        setConfirmSave(false)
    }
    const debitornamesetup=(name)=>{
        if(name){
            let obj=JSON.parse(name);
            SetDebtarname(obj && obj);
        }
    }
    let total = 0;
    let totalamount = 0;
    billing.forEach((da) => {
        total += da.Amout;
    })
    let discount = 0;
    alldataIndireact_direact.forEach((v) => {
        if (v.percentage != "") {
            discount = total * (v.percentage / 100)
            totalamount = total - Math.round(discount)

        } else {
            totalamount -= v.amount
        }
    })
    let wordstotal = toWords.convert(total);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    async function SaveBilldata(){
        if (billing.length == 0) {
            toast.info("bill is empty")
            return;
        }
        else {
            let bill = JSON.stringify(billing);
            let ledger_cr = "";
            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_cr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_cr = cashLedger.id;
                }
            }
            let data = { 
                date:datelocal, 
                bills:JSON.stringify({bill:bill}), 
                total, 
                frm_id: auth.id, 
                bos_no: bosNo, 
                ledger_cr, 
                credit_no: creNo, 
                slug:`creditnote-${creNo}`,
                ledger_dr:creditnote.id,
                under:"creditnote"
            }
            const creditnoteResponse=await axios.post(`${props.url}/insert_creditnote/${auth.id}_s_p_cn_dn`, data)
            if(creditnoteResponse.data){
                billing.forEach((bill)=>{
                    let billdata={
                        date:datelocal,
                        particulars:bill.pro_id, 
                        rate:bill.rate, 
                        quantity:bill.quaty,
                        unit:bill.unit,
                        total:bill.Amout ,
                        frm_id:auth.id,
                        bill_id:`creditnote-${creNo}`,
                        voucher:"creditnote"
                    }
                    // console.log(billdata);
                    axios.post(`${props.url}/insert_stock/${auth.id}_stock`,billdata)

                })
                setConfirmSave(true);
                toast.success("save");
            }
           
        }
    }
    let UpdateBilldata = async () => {
        try {
            if (!Array.isArray(billing) || billing.length === 0) {
                toast.error("Voucher is Empty");
                return;
            }
            let bill = JSON.stringify(billing);
            let ledger_cr = "";
            
            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_cr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_cr = cashLedger.id;
                }
            }
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill }),
                total: totalamount != 0 ? totalamount : total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_cr:ledger_cr,
                ledger_dr: creditnote.id,
                slug: `creditnote-${creNo}`,
                under: "creditnote"
            };
    
            let res = await axios.put(`${props.url}/viewdataupdate/${auth.id}_s_p_cn_dn/creditnote/${params.slug}`, data);
            
            if (res.data) {
                // Collect promises for batch processing
                const promises = billing.map(async (item) => {
                    if (!('stockid' in item)) {
                        let billdata = {
                            date: datelocal,
                            particulars: item.pro_id,
                            rate: item.rate,
                            quantity: item.quaty,
                            unit: item.unit,
                            total: item.Amout,
                            frm_id: auth.id,
                            bill_id: `creditnote-${creNo}`,
                            voucher: "creditnote"
                        };
                        console.log(billdata);
                        await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billdata);
                    }
                });
                await Promise.all(promises);
                // Redirect after all operations are done
                redirect('/dashboard');
            }
        } catch (error) {
            console.error("Error updating bill data:", error);
            // You can add further error handling here if needed, like showing an alert to the user
        }
    }
    const CrandDr=(val)=>{
        if(val){
            let obj=JSON.parse(val);
            setCreditnote(obj)
        }
    }
    const UpdateVoucher=(objs)=>{
        setVocherData(objs);
    }
    const Indireact_and_direact=(v)=>{
        setAlldataIndireact_direact(v);
    }
    const billcontroler=(val)=>{
        setBillControler(val)
    }
    const returnBillno=(bn)=>{
        setBosNo(bn);
    }
    const deleteItem=(index)=>{
        setDeleteIndex(index)
    }
    return (
        <div>
            <GlobalKeyListener onSave={SaveBilldata} onPrint={printbill} onNew={newBill}/>
            <div>
            </div>
                <div className='row text-center '>
                    <div className='col-sm-2 border  '>
                    <Invoiceaddpanel adbilldata={AddBilledata} url={props.url} localdate={localdateset} settingcontrol={props.setting} vocheri={"creditnote"} bosno={props.bosno} billNo={billofsplly} newbill={newbill} debitornamesetup={debitornamesetup} setupledcranddr={CrandDr} voucherupdate={UpdateVoucher} indireactanddireact={Indireact_and_direact} billcontroler={billcontroler} returnBillno={returnBillno} deletebillItem={deleteIndex} deleteItem={deleteItem}/>
                    </div>
                    <div className={setting.invoice == "invoice-1"?'col-sm-10 d-flex justify-content-center mt-5 mb-5':"col-md-10"} me-5 mx-5 >
                        <div >
                            <div class="me-5 mx-5" ref={printref}>
                                {
                                     setting.invoice == "invoice-1" ?<Creditnote_invoice_one url={props.url} bosNo={bosNo} creNo={creNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem}/>
                                     :<Creditnote_invoice_two url={props.url} bosNo={bosNo} creNo={creNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} BillControler={BillControler} invoicdesc={setting.invoicedescription}/>
                                }

                            </div>
                            {
                                vocherdata ? <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                    <div class="container-fluid">
                                        <button onClick={UpdateBilldata} className='btn btn-success' >Update</button>
                                        <button onClick={printbill} className='btn btn-primary' id="printfun" >Print</button>
                                    </div>
                                </nav> : <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                    <div class="container-fluid">

                                        <button onClick={SaveBilldata} className='btn btn-primary' ><i class="bi bi-floppy-fill"></i> Save</button>
                                        <button onClick={printbill} className='btn btn-primary' id="printfun"  disabled={confirmsave ? false : true}><i class="bi bi-printer"></i> Print</button>
                                        <button className='btn btn-dark' onClick={() => newBill()} disabled={confirmsave ? false : true}><i class="bi bi-file-earmark-plus"></i> New</button>
                                        {/* <a href='' className='btn btn-dark' disabled>New</a> */}
                                    </div>
                                </nav>
                            }



                        </div>
                        {/* <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <LedgerPop url={props.url} onChildStateChange={LedgerData}/>
                        </div> */}
                    </div>
                    <ToastContainer />
                </div>
            </div>
    )
}

export default CreditNote