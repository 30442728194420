import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import RegisterPrint_invoice from './RegisterPrint_invoice';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
function SaleRegister(props) {
    const printref = useRef();
    const { auth } = useUser();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [saledata, setSaleData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [total, setTotal] = useState(0);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    useEffect(() => {
        filterData();
    }, [startDate, endDate]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filterData = () => {
        const filtered = filterdata.filter(item => {
            const itemDate = new Date(item.date.split('/').reverse().join('-'));

            const rangeStartDate = startDate ? new Date(startDate) : null;

            const rangeEndDate = endDate ? new Date(endDate) : null;
            if (rangeStartDate && itemDate < rangeStartDate) {
                return false;
            }
            if (rangeEndDate && itemDate > rangeEndDate) {
                return false;
            }
            return true;
        });
        // console.log(filtered);
        setSaleData(filtered);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch sale data
                const saleResponse = await axios.get(`${props.url}/sale/${auth.id}_s_p_cn_dn`);
                var sortedSaleData = saleResponse.data;
                const updatedSalesDataPromises = sortedSaleData.map(async (saleItem) => {
                    try {
                        const ledgerResponse = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${saleItem.ledger_dr}`);
                        const ledgerData = ledgerResponse.data;
                        return {
                            ...saleItem,
                            ledger_dr: ledgerData.name
                        };
                    } catch (ledgerError) {
                        console.error(`Failed to fetch ledger data for ledger_dr ${saleItem.ledger_dr}:`, ledgerError);
                        return saleItem; // Return the original item if the ledger request fails
                    }
                });

                sortedSaleData = await Promise.all(updatedSalesDataPromises);
                const LedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/sale`);
                const LedgerData = LedgerResponse && LedgerResponse.data;
                const journaldata = [];

                for (const ledger of LedgerData) {
                    try {
                        const journalResponse = await axios.get(`${props.url}/journal_ledger/${auth.id}_journal/${ledger.id}`);
                        const sortedJournalData = journalResponse.data;

                        for (const element of sortedJournalData) {
                            try {
                                let journalTotal;

                                // Parse the 'total' JSON string safely
                                try {
                                    journalTotal = JSON.parse(element.total);
                                } catch (error) {
                                    console.error('Failed to parse total JSON:', error);
                                    continue; // Skip this element if parsing fails
                                }

                                // Check if ledger.id exists in journalTotal keys
                                if (Object.keys(journalTotal).includes(`${ledger.id}`)) {
                                    const total = (journalTotal[ledger.id].credit || 0) - (journalTotal[ledger.id].debit || 0);

                                    // Fetch ledger_dr name
                                    const ledgerDrResponse = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${element.ledger_dr}`);
                                    const ledgerDrData = ledgerDrResponse.data;

                                    // Fetch ledger_cr name
                                    const ledgerCrResponse = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${element.ledger_cr}`);
                                    const ledgerCrData = ledgerCrResponse.data;

                                    // Create the processed element with the calculated total and updated ledger names
                                    const processedElement = {
                                        ...element,
                                        total: total,
                                        ledger_dr: ledgerDrData.name,
                                        ledger_cr: ledgerCrData.name
                                    };

                                    // Push the processed element into journaldata
                                    journaldata.push(processedElement);
                                }
                            } catch (elementError) {
                                console.error(`Failed to process journal entry for ledger ${ledger.id}:`, elementError);
                            }
                        }
                    } catch (journalError) {
                        console.error(`Failed to fetch journal data for ledger ${ledger.id}:`, journalError);
                    }
                }
                // Merge sale and journal data
                const mergedData = [...sortedSaleData, ...journaldata];
                const sortedMergedData = sortDataByDate(mergedData);
                console.log(mergedData);
                // Update state with merged and sorted data
                setSaleData(sortedMergedData);
                setFilterdata(sortedMergedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.url, auth.id]);
    useEffect(() => {
        let total = 0;
        saledata.forEach((data) => {
            total += parseInt(data.total);
        })
        setTotal(total);
    }, [saledata])
    const sortDataByDate = (data) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.date.split('/').reverse().join('-'));
            const dateB = new Date(b.date.split('/').reverse().join('-'));
            return dateA - dateB;
        });
    };
    return (
        <div>
            <GlobalKeyListener />
            <div className='container'>
                <div className='row mb-4 '>
                    <div className='col-md-6'>
                        <h1 className='text-decoration-underline'>List Of All Sale Voucher</h1>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <input type='date' value={startDate} onChange={handleStartDateChange} ></input>
                        <label>to</label>
                        <input type='date' value={endDate} onChange={handleEndDateChange}></input>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Vch No</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    saledata && saledata.map((data) =>
                                        <tr>
                                            <th scope="row">{data.date}</th>
                                            <td>{data.ledger_dr != "Sale" ? data.ledger_dr : data.ledger_cr}</td>
                                            <td>{data.bos_no}</td>
                                            <td>&#8377;{data.total}</td>
                                            <td>
                                                <Link className='btn btn-primary' to={`/${data.under}/${data.slug}`}><i className='bi bi-pen'></i></Link>&nbsp;
                                            </td>
                                        </tr>
                                    )

                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td className='fw-bold'>Total</td>
                                    <td className='fw-bold'>&#8377;{total}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='text-center mt-2 '>
                        <button className='btn btn-primary' onClick={printbill} id="printfun"><i class="bi bi-printer"></i> Print</button>
                    </div>
                </div>
            </div>
            <div className='row d-none' >
                <div className='text-center' ref={printref}>
                    <RegisterPrint_invoice saledata={saledata} total={total} register={"Sale"} filterdate={startDate && endDate ? `${startDate} to ${endDate}` : null} />
                </div>
            </div>
        </div>
    )
}

export default SaleRegister