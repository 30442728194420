import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

function MyPro(props) {
    const { auth } = useUser();
    const printRef = useRef();
    const [name, setName] = useState("");
    const [salePrice, setSalePrice] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [stateRecall, setStateRecall] = useState(0);
    const [viewData, setViewData] = useState([]);
    const [unit, setUnit] = useState("");
    const [error, setError] = useState({
        name: "",
        salePrice: "",
        purchasePrice: "",
        unit: "",
        quantity: ""
    });

    const [unitData, setUnitData] = useState([
        { sym: "BAG", name: "BAGS" },
        { sym: "BAL", name: "BALE" },
        { sym: "BDL", name: "BUNDLES" },
        { sym: "BKL", name: "BUCKLES" },
        { sym: "BOU", name: "BILLIONS OF UNITS" },
        { sym: "BOX", name: "BOX" },
        { sym: "BTL", name: "BOTTLES" },
        { sym: "BUN", name: "BUNCHES" },
        { sym: "CAN", name: "CANS" },
        { sym: "CBM", name: "CUBIC METER" },
        { sym: "CCM", name: "CUBIC CENTIMETER" },
        { sym: "CMS", name: "CENTIMETER" },
        { sym: "CTN", name: "CARTONS" },
        { sym: "DOZ", name: "DOZEN" },
        { sym: "DRM", name: "DRUM" },
        { sym: "GGR", name: "GREAT GROSS" },
        { sym: "GMS", name: "GRAMS" },
        { sym: "GRS", name: "GROSS" },
        { sym: "GYD", name: "GROSS YARDS" },
        { sym: "KGS", name: "KILOGRAMS" },
        { sym: "KLR", name: "KILOLITRE" },
        { sym: "KME", name: "KILOMETRE" },
        { sym: "MLT", name: "MILLILITRE" },
        { sym: "MTR", name: "METERS" },
        { sym: "MTS", name: "METRIC TON" },
        { sym: "NOS", name: "NUMBERS" },
        { sym: "PAC", name: "PACKS" },
        { sym: "PCS", name: "PIECES" },
        { sym: "PRS", name: "PAIRS" },
        { sym: "QTL", name: "QUINTAL" },
        { sym: "ROL", name: "ROLLS" },
        { sym: "SET", name: "SETS" },
        { sym: "SQF", name: "SQUARE FEET" },
        { sym: "SQM", name: "SQUARE METERS" },
        { sym: "SQY", name: "SQUARE YARDS" },
        { sym: "TBS", name: "TABLETS" },
        { sym: "TGM", name: "TEN GRAMS" },
        { sym: "THD", name: "THOUSANDS" },
        { sym: "TON", name: "TONNES" },
        { sym: "TUB", name: "TUBES" },
        { sym: "UGS", name: "US GALLONS" },
        { sym: "UNT", name: "UNITS" },
        { sym: "YDS", name: "YARDS" },
        { sym: "OTH", name: "OTHERS" },
    ]);

    const navigate = useNavigate();
    const printBill = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        getData();
        setEditData([]);
    }, [stateRecall]);

    const getData = async () => {
        if (auth) {
            const token = JSON.parse(localStorage.getItem('token'));

            if (!token) {
                navigate('/');
                return;
            }

            try {
                const response = await axios.get(`${props.url}/show_products/${auth.id}_product/${auth.id}_stock`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    toast.error('Error fetching data. Please try again later.');
                }
            }
        } else {
            navigate('/');
        }
    };

    const addData = async (e) => {
        e.preventDefault();
        
        // Form validation
        let validation = {};
        if (!name || name.trim() === "") {
            validation.name = "Product name is required";
        }
    
        if (!salePrice || isNaN(salePrice) || salePrice <= 0) {
            validation.salePrice = "Sale price must be a positive number";
        }
    
        if (!purchasePrice || isNaN(purchasePrice) || purchasePrice <= 0) {
            validation.purchasePrice = "Purchase price must be a positive number";
        }
    
        if (!quantity || isNaN(quantity) || quantity <= 0) {
            validation.quantity = "Quantity must be a positive number";
        }
    
        if (!unit || unit.trim() === "") {
            validation.unit = "Unit is required";
        }
    
        setError(validation);
    
        if (Object.keys(validation).length === 0) {
            let productExist = data.some(obj => obj.name === name);
    
            if (productExist) {
                toast.error("Product already exists");
                return;
            }
    
            // Prepare product data
            let productData = {
                name,
                sale_price: salePrice,
                purchase_price: purchasePrice,
                frm_id: auth.id
            };
    
            try {
                // Insert product
                let productResponse = await axios.post(`${props.url}/insert_product/${auth.id}_product`, productData);
                console.log("Product Response:", productResponse.data);
    
                if (productResponse.data) {
                    let date = new Date();
                    let dd = String(date.getDate()).padStart(2, '0');
                    let mm = String(date.getMonth() + 1).padStart(2, '0');
                    let yyyy = date.getFullYear();
                    let formattedDate = `${dd}/${mm}/${yyyy}`;
    
                    // Prepare bill data
                    let billData = {
                        date: formattedDate,
                        particulars: productResponse.data.insertId,
                        rate: purchasePrice,
                        quantity: quantity,
                        total: quantity * purchasePrice,
                        frm_id: auth.id,
                        bill_id: null,
                        voucher: "opening-balance",
                        unit
                    };
    
                    // Insert stock
                    let stockResponse = await axios.post(`${props.url}/insert_stock/${auth.id}_stock`, billData);
                    console.log("Stock Response:", stockResponse.data);
    
                    if (stockResponse.data) {
                        getData();
                        setName("");
                        setSalePrice(0);
                        setPurchasePrice(0);
                        setQuantity(0);
                        setUnit("");
                        toast.success("Product Added Successfully");
                    }
                }
            } catch (error) {
                console.error("Error adding product or stock:", error);
                toast.error("There was an error adding the product. Please try again.");
            }
        }
    };
    

    const EditData =async (id) => {
        let response=await axios.get(`${props.url}/show_product/${auth.id}_product/${auth.id}_stock/${id}`);
        setEditData(response.data);
    };

    const updateData = async(id) => {
        let Pname = name != "" ? name : editData.name;
        let Psale_price = salePrice != 0 ? salePrice : editData.salePrice;
        let purchas_price = purchasePrice != 0 ? purchasePrice : editData.purchasePrice;
        let squantity = quantity != 0 ? quantity : editData.quaty;
        let srate = purchasePrice != 0 ? purchasePrice : editData.purchasePrice;
        let stotal = quantity * purchasePrice != 0 ? quantity * purchasePrice : editData.total;
        let sunit = editData.unit;
        let shotel_id = editData.hotel_id;
        let sbill_id = editData.bill_id;
        let svoucher = editData.voucher;
        let data = {
            name: Pname,
            sale_price: Psale_price,
            purchas_price: purchas_price,
            hotel_id: editData.hotel_id
        };
        let stockdata = {
            particulars: editData.id,
            quantity: squantity,
            rate: srate,
            total: stotal,
            unit: sunit,
            hotel_id: shotel_id,
            bill_id: sbill_id,
            voucher: svoucher
        }
        try {
            // Update product
            const productResponse = await axios.put(`${props.url}/update_product/${auth.id}_product/${id}`,data);

            if (productResponse) {
                // Update stock
                const stockResponse = await axios.put(`${props.url}/update_stock/${auth.id}_stock/${editData.stock_id}`,stockdata);
                if (stockResponse.data){
                    setStateRecall(1 + id);
                    setName("");
                    setSalePrice(0);
                    setPurchasePrice(0);
                    setQuantity(0);
                    setUnit("");
                    setEditData([]);
                    getData();
                    toast.success("Product Updated Successfully");
                } else {
                    toast.error("Error updating stock data.");
                }
            } else {
                toast.error("Error updating product data.");
            }
        } catch (error) {
            console.error('Error updating product or stock:', error);
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                toast.error('Error updating product or stock. Please try again later.');
            }
        }
    };

    const deleteData = async (id) => {
        var result = confirm("Are you sure?");
        
        if (!result) {
            return;
        }
        
        try {
            const responsestock = await axios.get(`${props.url}/check-stock-product/${auth.id}_stock/${id}`);
            
            // Check if any item in the responsestock data array has a "voucher" value not equal to "opening-balance"
            let canDelete = true;
            responsestock.data.forEach(item => {
                if (item.voucher !== "opening-balance") {
                    canDelete = false;
                }
            });
    
            if (!canDelete) {
                toast.error("Cannot delete product with existing stock entries.");
                return;
            }
    
            const response_product = await axios.delete(`${props.url}/delete_product/${auth.id}_product/${id}`);
            const response_stock = await axios.delete(`${props.url}/delete_stock/${auth.id}_stock/${id}`);
            if (response_product.data && response_stock.data) {
                getData();
                toast.success("Product deleted successfully");
            }
        } catch (error) {
            console.error("Error deleting product:", error);
            toast.error("There was an error deleting the product. Please try again.");
        }
    };
    
    const ViewData = async (id) => {
        try {
            const response = await axios.get(`${props.url}/view_product/${auth.id}_product/${auth.id}_stock/${id}`);
            let result = response.data.sort((a, b) => {
                let dateA = a.date.split('/').reverse().join('');
                let dateB = b.date.split('/').reverse().join('');
                return dateA.localeCompare(dateB);
            });
    
            let viewdata = [];
            let openingdata = 0;
            result.forEach((obj) => {
                let journalunder="";
                if(obj.voucher=="journal"){

                    let unit=JSON.parse(obj.unit);
                    journalunder=unit.ledger;
                }
                let balanceChange = 0;
                if (obj.voucher === "opening-balance") {
                    openingdata = parseInt(obj.quantity);
                } else if (obj.voucher === "sale" || obj.voucher === "debitnote" || obj.voucher=="payment_in"  || obj.voucher=="journal" && journalunder && journalunder=="sale" || obj.voucher === "input") {
                    balanceChange -=parseInt(obj.quantity);
                } else if (obj.voucher === "purchase" || obj.voucher === "creditnote" || obj.voucher=="payment_out" || obj.voucher=="journal" && journalunder && journalunder=="purchase"|| obj.voucher === "output") {
                    balanceChange += parseInt(obj.quantity);
                }
                openingdata += balanceChange;
    
                // let displayVoucher = obj.voucher;
                // if (obj.bill_id === null) {
                //     displayVoucher = "opening-balance";
                // } else if (obj.voucher === "input" || obj.voucher === "output") {
                //     displayVoucher = obj.bill_id.replace(obj.voucher, "transfer");
                // }
    
                viewdata.push({
                    id:obj.id,
                    table: obj.voucher,
                    voucher: obj.bill_id == null ? "opening-balance" : obj.voucher == "input" || obj.voucher == "output" ? obj.bill_id = obj.bill_id.replace(obj.voucher, "transfer") : obj.bill_id,
                    date: obj.date || null,
                    quantity: obj.quantity,
                    balance: openingdata,
                    stockid:obj.stock_id,
                    productid:obj.particulars
                });
            });
            setViewData(viewdata);
        } catch (error) {
            console.error("Error fetching or processing data:", error);
            toast.error("There was an error fetching the data. Please try again.");
        }
    };
    

    const searchPro = (e) => {
        let search = e.target.value.toLowerCase();
        let productRows = document.querySelectorAll(".product-row");

        productRows.forEach(row => {
            let productName = row.querySelector(".product-name").innerText.toLowerCase();
            if (productName.includes(search)) {
                row.style.display = "";
            } else {
                row.style.display = "none";
            }
        });
    };
    const editstock = (slug, table) => {
        const modals = document.getElementsByClassName('modal');
        // on every modal change state like in hidden modal
        for (let i = 0; i < modals.length; i++) {
            modals[i].classList.remove('show');
            modals[i].setAttribute('aria-hidden', 'true');
            modals[i].setAttribute('style', 'display: none');
        }
        // get modal backdrops
        const modalsBackdrops = document.getElementsByClassName('modal-backdrop');
        // remove every modal backdrop
        for (let i = 0; i < modalsBackdrops.length; i++) {
            document.body.removeChild(modalsBackdrops[i]);
        }
        if (table == "sale") {
            navigate(`/sale/${slug}`)
        } else if (table == "purchase") {
            navigate(`/purchase/${slug}`)
        } else if(table=="payment_in"){
            navigate(`/payment-in/${slug}`)
        }else if(table=="payment_out"){
            navigate(`/payment-out/${slug}`)
        }
        else if (table == "input") {
            let result = slug.replace("transfer", "input");
            let result1 = slug.replace("transfer", "output");
            navigate(`/transfer/${result}/${result1}`)
        } else if (table == "output") {
            let result = slug.replace("transfer", "input");
            let result1 = slug.replace("transfer", "output");
            navigate(`/transfer/${result}/${result1}`)
        }
    }
    const deletestock=async(id,slug,vtype,productid)=>{
        alert(productid)
        // if (confirm("do you really delete this invoice")) {
        //     let table="";
        //     if(vtype=="sale" || vtype=="purchase" || vtype=="credit_note" || vtype=="debit_note"){
        //         table="s_p_cn_dn";
        //     }else if(vtype=="payment_in" || vtype=="payment_out"){
        //         table="pi_po";
        //     }else if(vtype=="journal"){
        //         table="journal";
        //     }
        //     let res=await axios.delete(`${props.url}/viewdata_delete/${auth.id}_${table}/${id}`);
        //     if (res.status == 200) {
        //         let res=await axios.delete(`${props.url}/viewstock_delete/${auth.id}_stock/${slug}`)
        //         if(res.status==200){
        //             ViewData(productid)
        //         }
                
        //     }
            
        // }
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <GlobalKeyListener />
            <div className='row'>
                <div className="col-sm-3 mt-2">
                    <h4>Add Product</h4>
                    <form onSubmit={addData}>
                        <div className="form-group">
                            <label htmlFor="name">Product Name</label>
                            <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter product name" />
                            {error.name && <span className="text-danger">{error.name}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="salePrice">Sale Price</label>
                            <input type="number" className="form-control" id="salePrice" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} placeholder="Enter sale price" />
                            {error.salePrice && <span className="text-danger">{error.salePrice}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="purchasePrice">Purchase Price</label>
                            <input type="number" className="form-control" id="purchasePrice" value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)} placeholder="Enter purchase price" />
                            {error.purchasePrice && <span className="text-danger">{error.purchasePrice}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="quantity">Quantity</label>
                            <input type="number" className="form-control" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Enter quantity" />
                            {error.quantity && <span className="text-danger">{error.quantity}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="unit">Unit</label>
                            <select className="form-control" id="unit" value={unit} onChange={(e) => setUnit(e.target.value)}>
                                <option value="">Select unit</option>
                                {unitData.map((unitItem, index) => (
                                    <option key={index} value={unitItem.sym}>{unitItem.name}</option>
                                ))}
                            </select>
                            {error.unit && <span className="text-danger">{error.unit}</span>}
                        </div>
                        <button type="submit" className="btn btn-primary mt-3 mb-3">Add</button>
                    </form>
                </div>
                <div className="col-md-9 mt-2">
                    <div className="row">
                        <div className="col-8">
                            <h4>Products</h4>
                        </div>
                        <div className="col-4">
                            <input type="text" className="form-control" onInput={searchPro} placeholder="Search product..." />
                        </div>
                    </div>
                    <div class="table-responsive-sm">
                        <table className="table table-bordered mt-3">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Sale Price</th>
                                    <th>Purchase Price</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.length > 0 ? data.map((item, index) => (
                                    <tr key={++index} className="product-row">
                                        <td >{++index}</td>
                                        <td className="product-name">{item.name}</td>
                                        <td>{item.sale_price}</td>
                                        <td>{item.purchase_price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.unit}</td>
                                        <td>
                                            <button className="btn btn-warning btn-sm mr-2" onClick={() => EditData(item.id)} data-bs-toggle="modal" data-bs-target="#update" data-bs-whatever="@mdo">Edit</button>
                                            <button className="btn btn-danger btn-sm mr-2" onClick={() => deleteData(item.id)} >Delete</button>
                                            <button type='button' className="btn btn-info btn-sm" onClick={() => ViewData(item.id)} data-bs-toggle="modal" data-bs-target="#viewModal" data-bs-whatever="@mdo">View</button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No products found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* View Modal */}
            <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-labelledby="viewModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" >
                            <table class="table" >
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Voucher</th>
                                        <th scope="col">Quantity</th>
                                        {/* <th scope="col">Rate</th>
                                        <th scope="col">Total</th> */}
                                        <th scope="col">Balance</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        viewData && viewData.map((st, index) =>
                                            <tr>
                                                <td>{st.date}</td>
                                                <td>{st.voucher}</td>
                                                <td>{st.quantity}</td>

                                                <td>{st.balance}</td>
                                                {
                                                    st.table == "opening-balance" ? null : <td>
                                                        <button className='btn btn-primary' onClick={() => editstock(st.voucher, st.table)} data-bs-dismiss="modal"><i className='bi bi-pen'></i></button>&nbsp;
                                                        {/* <button className='btn btn-danger' onClick={() => deletestock(st.id,st.voucher,st.table,st.productid)}><i className='bi bi-trash'></i></button> */}
                                                    </td>
                                                }

                                                {/* <td>{st.total}</td> */}
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn btn-primary" onClick={printBill} id="printfun" >Print</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="update" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Product</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div class="mb-3 col-12" >
                                    <label for="recipient-name" class="col-form-label">Name:</label>
                                    <input type="text" class="form-control" id="recipient-name" defaultValue={editData && editData.name} onChange={(e) => setName(e.target.value)}   ></input>
                                </div>
                                <div class="mb-3 col-6" >
                                    <label for="message-text" class="col-form-label">Quantity:</label>
                                    <input type="number" class="form-control" id="recipient-name" defaultValue={editData.quantity} onChange={(e) => setQuantity(parseInt(e.target.value))}  ></input>
                                </div>
                                <div class="mb-3 col-6">
                                    <label for="message-text" class="col-form-label">Unit:</label>
                                    <select className='form-control' onChange={(e) => setUnit(e.target.value)} >
                                        <option value={editData.unit}>{editData.unit}</option>
                                        {
                                            unitData && unitData.map((uqc) =>
                                                <option value={uqc.sym}>{uqc.name}</option>
                                            )
                                        }
                                    </select>
                                    <div >{error && error.unit ? <span className='text-danger'>{error.unit}</span> : null}</div>
                                </div>
                                <div class="mb-3 col-12" >
                                    <label for="message-text" class="col-form-label">Sale Price:</label>
                                    <input type="number" class="form-control" id="recipient-name" defaultValue={editData.sale_price} onChange={(e) => setSalePrice(parseInt(e.target.value))}></input>
                                </div>
                                <div class="mb-3 col-12" >
                                    <label for="message-text" class="col-form-label">Purchase Price:</label>
                                    <input type="number" class="form-control" id="recipient-name" defaultValue={editData.purchase_price} onChange={(e) => setPurchasePrice(parseInt(e.target.value))}></input>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success" onClick={() => updateData(editData.id)}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container d-none'  >
                <div ref={printRef}>
                    <div className='row text-center'>
                        <h1>{auth.frm_name}</h1>
                        <h5>{auth.address}</h5>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <table class="table" >
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Voucher</th>
                                        <th scope="col">Quantity</th>
                                        {/* <th scope="col">Rate</th>
                                        <th scope="col">Total</th> */}
                                        <th scope="col">Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        viewData && viewData.map((st, index) =>
                                            <tr>
                                                <td>{st.date}</td>
                                                <td>{st.voucher}</td>
                                                <td>{st.quantity}</td>

                                                <td>{st.balance}</td>


                                                {/* <td>{st.total}</td> */}
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyPro;
