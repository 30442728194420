import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LedgerPop from './LedgerPop';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import Ledger from './Ledger';
function LedgerData(props) {
    const { auth } = useUser();
    const [name, setName] = useState(null);
    const [phoneNo, setPhoneNo] = useState(null);
    const [address, setAddress] = useState(null);
    const [debtordata, setDebtorData] = useState([]);
    const [bank_name, setBankName] = useState(null);
    const [accountNo, setAccountNo] = useState(null);
    const [othername, setOtherName] = useState(null);
    const [IFSC, setIFSC] = useState(null);
    const [selectInputControl, setSelectInputControl] = useState("debtor");
    const [ledger_id, setLedgerId] = useState(null);
    const [sale, setSale] = useState([]);
    const [purchase, setPurchase] = useState([]);
    const [ledgername, setLedgername] = useState([]);
    const [receiptdata, setReceiptdata] = useState([]);
    const [payment, setPayment] = useState([]);
    const [creditnote, setCreditNote] = useState([]);
    const [debitnote, setDebitNote] = useState([]);
    const [journal, setJournal] = useState([]);
    const [data,setData]=useState({});
    useEffect(() => {
        const LedgerData = async () => {
            let response = await axios.get(`${props.url}/show_ledgers/${auth.id}_ledger`);
            setDebtorData(response.data);
        }
        LedgerData();
    })
    const LedgerEdit = async (id) => {
        let res = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${id}`);
        if (res.data.under == "debtor") {
            setName(res.data.name);
            setPhoneNo(res.data.phoneno);
            setAddress(res.data.address);
            setSelectInputControl(res.data.under)
            setLedgerId(id)
        } else if (res.data.under == "bank") {
            setBankName(res.data.bank_name)
            setAccountNo(res.data.account_no);
            setIFSC(res.data.ifsc);
            setSelectInputControl(res.data.under)
            setLedgerId(id)
        } else {
            setOtherName(res.data.name);
            setSelectInputControl(res.data.under)
            setLedgerId(id)
        }
    }
    const LedgerUpdate = async (id) => {
        let data = {};
        const startsWithNumber = /^\d/;
        console.log((name && startsWithNumber.test(name)) || (bank_name && startsWithNumber.test(bank_name)) || (othername && startsWithNumber.test(othername)));
        if ((name && startsWithNumber.test(name)) || (bank_name && startsWithNumber.test(bank_name)) || (othername && startsWithNumber.test(othername))) {
            toast.error("Name cannot start with a number");
            return;
        }
        if (name && phoneNo && address) {
            data = { name: name, phoneno: phoneNo, address: address, frm_id: auth.id, under: selectInputControl };
        }
        else if (bank_name && accountNo && IFSC) {
            data = { name: bank_name, bank_name: bank_name, account_no: accountNo, ifsc: IFSC, frm_id: auth.id, under: selectInputControl };
        }
        else if (othername && selectInputControl != "debtor" && selectInputControl != "bank") {
            data = { name: othername, frm_id: auth.id, under: selectInputControl };
        }
        else {
            toast.info("Fields is Empty")
            return;
        }
        let ledgeruniqe = false;
        debtordata && debtordata.forEach(obj => {
            if (obj.name == data.name) {
                ledgeruniqe = true;
            }
        });
        if (ledgeruniqe) {
            toast.error("Ledger already exists");
            ledgeruniqe = false;
            return;
        }
        else {
            let res = await axios.put(`${props.url}/update_ledger/${auth.id}_ledger/${id}`, data);
            LedgerData();
            setName("");
            setPhoneNo("");
            setAddress("");
            setBankName("");
            setAccountNo("");
            setIFSC("");
            setOtherName("")
            toast.success("Ledger Update successfully");
        }
    }
    const LedgerDelete = async (id, ledgername) => {
        let VoucherResp = await axios.get(`${props.url}/vouchercheckledger/${id}`);
        if (VoucherResp.data) {
            toast.info("voucher is useing ledger")
            return;
        }
        else {
            if (confirm("you are delete this ledger")) {
                axios.delete(`${props.url}/delete_ledger/${auth.id}_ledger/${id}`)
            }
        }


    }
    
    return (
        <div>
            <GlobalKeyListener />
            <div className='container'>
                <div className='row text-center'>
                    <h1 className='text-decoration-underline'>LEDGERS</h1>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button className=' btn btn-dark' data-bs-toggle="modal" data-bs-target="#debtoradd">+</button>
                    </div>
                </div>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Ledger Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                debtordata && debtordata.map((led, index) =>
                                    <tr>
                                        <th scope="row">{++index}</th>
                                        <td>{led.name}</td>
                                        <td>{led.under}</td>
                                        <td>
                                            {
                                                led.name !== "Cash" && led.name !== "Purchase" && led.name !== "Sale" ?
                                                    <><button className='btn btn-primary text-uppercase text-decoration-none ' onClick={() => LedgerEdit(led.id)} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className='bi bi-pen'></i>
                                                    </button>&nbsp;
                                                        <button className=' btn btn-danger  text-uppercase text-decoration-none ' onClick={() => LedgerDelete(led.id, led.name)}> <i className='bi bi-trash'></i></button></> : null
                                            }
                                            &nbsp;
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ledgermodal" onClick={()=>setData({id:led.id,name:led.name})}><i className='bi bi-eye'></i></button>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <select class="modal-title form-select" >
                                <option value={selectInputControl} className='text-uppercase'>{selectInputControl.toLocaleUpperCase()}</option>
                            </select>
                            {/* <h5 class="modal-title" id="exampleModalLabel">DEBTOR</h5> */}
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {
                                selectInputControl == "debtor" ? <>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">Name:</label>
                                        <input type="text" class="form-control" value={name} onChange={(e) => setName(e.target.value)}></input>
                                    </div>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">PhoneNo:</label>
                                        <input type="text" class="form-control" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)}></input>
                                    </div>
                                    <div class="mb-3">
                                        <label for="recipient-name" class="form-label">Address:</label>
                                        <textarea class="form-control" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                    </div></>
                                    : selectInputControl == "bank" ? <>
                                        <div class="mb-3">
                                            <label for="recipient-name" class="form-label">Bank Name:</label>
                                            <input type="text" class="form-control" value={bank_name} onChange={(e) => setBankName(e.target.value)}></input>
                                        </div>
                                        <div class="mb-3">
                                            <label for="recipient-name" class="form-label">Account No:</label>
                                            <input type="number" class="form-control" value={accountNo} onChange={(e) => setAccountNo(e.target.value)}></input>
                                        </div>
                                        <div class="mb-3">
                                            <label for="recipient-name" class="form-label">IFSC:</label>
                                            <input type="text" class="form-control" value={IFSC} onChange={(e) => setIFSC(e.target.value)}></input>
                                        </div>
                                    </> : selectInputControl == "current-asset"
                                        || selectInputControl == "current-libility"
                                        || selectInputControl == "fixed-assest"
                                        || selectInputControl == "purchase"
                                        || selectInputControl == "sale"
                                        || selectInputControl == "sundary-creditor"
                                        || selectInputControl == "capital-account"
                                        || selectInputControl == "indirect-income"
                                        || selectInputControl == "indirect-expenses"
                                        || selectInputControl == "investments"
                                        || selectInputControl == "cash"
                                        || selectInputControl == "direct-expenses"
                                        || selectInputControl == "direct-income"

                                        ? <>
                                            <div class="mb-3">
                                                <label for="recipient-name" class="form-label">Name:</label>
                                                <input type="text" class="form-control" value={othername} onChange={(e) => setOtherName(e.target.value)}></input>
                                            </div>
                                        </> : null

                            }
                        </div>
                        <div class="modal-footer">
                            <div className="d-grid gap-2 col-6 mx-auto">
                                <button className="btn btn-success" type="button" onClick={() => LedgerUpdate(ledger_id)} >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <LedgerPop url={props.url} onChildStateChange={LedgerData} />
            </div>
            <div class="modal fade" id="ledgermodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header bg-primary text-white">
                            <h5 class="modal-title text-uppercase" id="exampleModalLabel">{data.name}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <Ledger url={props.url} data={JSON.stringify(data)}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default LedgerData