import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
const UserContext = createContext();
export const Authdata = ({ children ,url}) => {
    const [auth, setAuth] = useState({});
    const [authupdate,setAuthUpdate]=useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = JSON.parse(localStorage.getItem('token'));
    useEffect(() => {
        const fetchUserData = async () => {
             // Retrieve the token from local storage
            if (token) {
                try {
                    const response = await axios.get(`${url}/user-verify`, {
                        headers: {
                            'auth-token': token.token // Add the token to the Authorization header
                        }
                    });
                    setAuth(response.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        // Handle unauthorized access (e.g., redirect to login)
                        window.location.href = '/';
                    } else {
                        setError(error);
                    }
                } finally {
                    setLoading(false);
                }
            } 
        };
        fetchUserData();
    },[url,authupdate]); // Empty dependency array ensures this runs only once
    const reloadpage=()=>{
        setAuthUpdate(!authupdate);
    }
    const Authclear=()=>{
        setAuth({})
    }
    return (
        <UserContext.Provider value={{ auth,Authclear, loading, error ,reloadpage}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
