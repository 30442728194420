import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { encryptData, decryptData } from '../encryptionUtils';
import { useUser } from '../Authdata';
import LedgerPop from './LedgerPop';
import LedgerData from './LedgerData';

function Direact_and_Indireact({ url, onbilldata, slug, table }) {
    const { auth } = useUser();
    const [data, setData] = useState([]);
    const [particular, setParticular] = useState("");
    const [percentage, setPercentage] = useState("");
    const [amount, setAmount] = useState("");
    const [billing, setBilling] = useState([]);
    const [pagereload, setPageReload] = useState(true);
    const [error, setError] = useState({
        particular: "",
        amount: "",
        percentage: ""
    });

    useEffect(() => {
        const fetchData = async () => {
            if (slug) {
                try {
                    const res1 = await axios.get(`${url}/viewdata/${slug}/${table}/${auth.id}`);
                    if (res1 && res1.data) {
                        const bill = JSON.parse(res1.data.bill);
                        setBilling(JSON.parse(bill.indireact_and_direact));
                    }
                } catch (error) {
                    console.error('Error fetching viewdata:', error);
                }
            }

            try {
                const res2 = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
                if (res2 && res2.data) {
                    const filteredData = res2.data.filter(obj => 
                        ['direct-income', 'indirect-income', 'direct-expenses', 'indirect-expenses'].includes(obj.under)
                    );
                    setData(filteredData);
                }
            } catch (error) {
                console.error('Error fetching ledger data:', error);
            }
        };

        fetchData();
    }, [slug, url, auth.id, table, pagereload]);

    const addData = () => {
        let errors = {};
        if (!particular) {
            errors.particular = "Particular is required.";
        }
        if (!percentage && !amount) {
            errors.percentage = "Percentage or amount is required.";
            errors.amount = "Percentage or amount is required.";
        }

        setError(errors);

        if (Object.keys(errors).length === 0) {
            let billdata = [...billing, { particular: particular, percentage: percentage, amount: amount }];
            setBilling(billdata);
            setParticular("");
            setPercentage("");
            setAmount("");
            setError({ particular: "", amount: "", percentage: "" });
        }
    };

    const deleteData = () => {
        billing.pop();
        let billdata = [...billing];
        setBilling(billdata);
    };

    onbilldata(billing);
    const [showFirstModal, setShowFirstModal] = useState(false);

    const handleFirstModalClose = () => setShowFirstModal(false);
    const handleFirstModalShow = () => setShowFirstModal(true);

    const charges = (obj) => {
        setPageReload(!pagereload);
    };

    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Charges</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Particulars</label>
                            <select className='form-control' value={particular} onChange={(e) => setParticular(e.target.value)}>
                                <option value={""}>{"--Select--"}</option>
                                {
                                    data && data.map((val, index) =>
                                        <option key={index} value={val.name}>{val.name}</option>
                                    )
                                }
                            </select>
                            {error.particular && <div className="text-danger">{error.particular}</div>}
                            <button className='btn btn-link' onClick={handleFirstModalShow}>+Client</button>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Percentage</label>
                            <input type="number" className="form-control" value={percentage} onChange={(e) => setPercentage(e.target.value)} disabled={amount !== ""}></input>
                            {error.percentage && <div className="text-danger">{error.percentage}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Amount</label>
                            <input type="number" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} disabled={percentage !== ""}></input>
                            {error.amount && <div className="text-danger">{error.amount}</div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={deleteData}>Delete</button>
                        <button type="button" className="btn btn-primary" onClick={addData}>Add</button>
                    </div>
                </div>
            </div>
            <div className={`modal ${showFirstModal ? 'show' : ''}`} style={{ display: showFirstModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Charge</h4>
                            <button type="button" className="close" onClick={handleFirstModalClose}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <LedgerPop url={url} onChildStateChange={charges} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Direact_and_Indireact;
