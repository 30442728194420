import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../Authdata';
import AddPro from './AddPro';
function StockJournal(props) {
    const { auth } = useUser();
    const [vocherdata, setVocherData] = useState(null);
    const [reloadpage, setReloadpage] = useState(true);
    const [bosNo, setBosNo] = useState(0);
    const redirect = useNavigate();
    const [datelocal, setDatelocal] = useState("");
    const [data, setData] = useState([]);
    const [inputparticular, setInputParticular] = useState("");
    const [inputqty, setInputQty] = useState([]);
    const [inputquaty, setInputQuaty] = useState(0);
    const [inputrate, setInputRate] = useState(0);
    const [inputAmout, setInputAmoutes] = useState(0);
    const [inputunits, setInputUnits] = useState(0);
    const [outputparticular, setOutputParticular] = useState("");
    const [outputqty, setOutputQty] = useState([]);
    const [outputquaty, setOutputQuaty] = useState(0);
    const [outputrate, setOutputRate] = useState(0);
    const [outputAmout, setOutputAmoutes] = useState(0);
    const [outputunits, setOutputUnits] = useState(0);

    const [output, setOutput] = useState([]);
    const [input, setInput] = useState([]);

    let params = useParams();
    const [stock, setStock] = useState([]);
    const [totaloutput, setTotalOutput] = useState({
        outputqyt: 0,
        outputunit: "",
        outputamount: 0
    })
    var date = "";
    useEffect(() => {
        const GetData = async () => {
            try {
                if (params && params.inputslug && params.outputslug) {
                    const resTransfer = await axios.get(`${props.url}/transfer/${auth.id}/${params.inputslug}/${params.outputslug}`);
                    if (resTransfer.data) {
                        const bosnoget = params.inputslug.slice(6);
                        setBosNo(bosnoget);
                        setVocherData(resTransfer.data);
        
                        const inputArr = [];
                        const outputArr = [];
                        resTransfer.data.forEach((data) => {
                            const item = {
                                particular: data.name,
                                rate: parseInt(data.rate, 10),
                                quaty: data.quantity,
                                unit: data.unit,
                                Amout: parseInt(data.total, 10),
                                stock_id: data.stock_id
                            };
                            if (data.voucher === "input") {
                                inputArr.push({ ...item, proinput_id: data.id });
                            } else {
                                outputArr.push({ ...item, prooutput_id: data.id });
                            }
                        });
        
                        setInput(inputArr);
                        setOutput(outputArr);
                        setDatelocal(resTransfer.data.length > 0 ? resTransfer.data[0].date : "");
                    }
                } else {
                    redirect("/dashboard");
                }
        
                const resProducts = await axios.get(`${props.url}/show_products/${auth.id}_product/${auth.id}_stock`);
                console.log(resProducts.data);
                const filteredData = resProducts.data.filter(d => d.voucher === "opening-balance");
                setData(filteredData);
        
                if (!(params && params.inputslug && params.outputslug)) {
                    getbosno();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally, you could display an error message to the user or handle the error in some other way
            }
        };
        
        // Call the GetData function
        GetData();
        
    }, [reloadpage, params, props.url]);
    const getbosno = async () => {
        let res = await axios.get(`${props.url}/show_stock/${auth.id}_stock`);
        if (res.data) {
            let arr = res && res.data.filter((d) => d.voucher == "input" || d.voucher == "output" ? d : null)
            let bosn = 0
            arr && arr.forEach(element => {
                bosn = parseInt(element.bill_id.charAt(element.bill_id.length - 1));
            });
            console.log(bosn + 1);
            setBosNo(bosn + 1)
        }

    }
    useEffect(() => {
        setOutput(output);
        setInput(input);

    }, [reloadpage])
   
    let total = 0;
    input.forEach((da) => {
        total += da.Amout;
    })
   
    let totalqut = 0;
    let totalunit = "";
    input.forEach((items) => {
        totalqut += parseInt(items.quaty)
        totalunit = items.unit;
    })
   
    const InputParRate = (v) => {
        setInputParticular(v)
        data && data.forEach((el) => {
            if (el.name == v) {
                setInputRate(el.sale_price)
                let Qty = [];
                for (let index = 1; index <= el.quaty; index++) {
                    Qty.push(index)
                }
                setInputQty(Qty)
                setInputUnits(el.unit)
            }
        })

    }
    const InputAmout = (q) => {

        setInputQuaty(q)
        let amout = inputrate * q;
        setInputAmoutes(amout)

    }
    const OutputParRate = (v) => {
        setOutputParticular(v)
        data && data.forEach((el) => {
            if (el.name == v) {
                setOutputRate(el.sale_price)
                let Qty = [];
                for (let index = 1; index <= el.quaty; index++) {
                    Qty.push(index)
                }
                setOutputQty(Qty)
                setOutputUnits(el.unit)
            }
        })

    }
    const OutputAmout = (q) => {
        setOutputQuaty(q)
        let amout = outputrate * q;
        setOutputAmoutes(amout)

    }
    let outputqyt = 0;
    let outputunit = "";
    let outputamount = 0;
    output.forEach((i) => {
        outputqyt += parseInt(i.quaty);
        outputunit = i.unit;
        outputamount += i.Amout;
    })
    const NewOutput = () => {
        let prooutput_id = 0;
        data.forEach((val) => {
            if (val.name == outputparticular) {
                prooutput_id = val.id
            }
        })
        let next = [...output, { particular: outputparticular, rate: outputrate, quaty: outputquaty, unit: outputunits, Amout: outputAmout, prooutput_id }];
        setOutput(next);
    }
    const NewInput = () => {
        let proinput_id = 0;
        data.forEach((val) => {
            if (val.name == inputparticular) {
                proinput_id = val.id
            }
        })
        let next = [...input, { particular: inputparticular, rate: inputrate, quaty: inputquaty, unit: inputunits, Amout: inputAmout, proinput_id }];
        setInput(next);
    }
    
    const Savedata = async() => {
        input.forEach(element => {
            axios.post(`${props.url}/insert_stock/${auth.id}_stock`, {
                date: date,
                particulars: element.proinput_id,
                rate: element.rate,
                quantity: element.quaty,
                unit: element.unit,
                total: element.Amout,
                frm_id: auth.id,
                bill_id: `input-${bosNo}`,
                voucher: "input"
            })
        });
        output.forEach(element => {
            axios.post(`${props.url}/insert_stock/${auth.id}_stock`, {
                date: date,
                particulars: element.prooutput_id,
                rate: element.rate,
                quantity: element.quaty,
                unit: element.unit,
                total: element.Amout,
                frm_id: auth.id,
                bill_id: `output-${bosNo}`,
                voucher: "output"
            })
        });
        toast.success("stock journal save successfully")
        setData([]);
        setInput([]);
        setOutput([]);
        setInputUnits("");
        setInputAmoutes(0);
        setInputRate(0);
        setInputQuaty(0)
        setOutputUnits(0);
        setOutputAmoutes(0);
        setOutputRate(0);
        setOutputQuaty(0)
        let res=await axios.get(`${props.url}/show_stock/${auth.id}_stock`);
        if(res.data){
            let arr = res && res.data.filter((d) => d.voucher == "input" || d.voucher == "output" ? d : null)
            let bosn = 0
            arr && arr.forEach(element => {
                bosn = parseInt(element.bill_id.charAt(element.bill_id.length - 1));
            });
            setBosNo(bosn + 1)
        }
           

        // console.log("save successfully");
        // axios.post(`${props.url}/stock`, billdata)
    }
    const Updatedata = () => {
        // console.log(input,output);
        input.forEach(element => {
            if (element.stock_id) {
                // console.log(element);
                axios.put(`${props.url}/update_stock/${auth.id}_stock/${element.stock_id}`, { date: date });
            } else {
                axios.post(`${props.url}/insert_stock/${auth.id}_stock`, { date: date, particulars: element.proinput_id, rate: element.rate, quaty: element.quaty, unit: element.unit, total: element.Amout, hotel_id: auth.id, bill_id: `input-${bosNo}`, voucher: "input" })
            }
            // axios.put(`${props.url}/stock/${element.stock_id}`, { date: date, particulars:element.proinput_id, rate: element.rate, quaty: element.quaty,unit:element.unit,total: element.Amout, hotel_id: auth.id, bill_id: `input-${bosNo}`, voucher: "input" })
        });
        output.forEach(element => {
            if (element.stock_id) {
                // console.log(element);
                axios.put(`${props.url}/update_stock/${auth.id}_stock/${element.stock_id}`, { date: date });
            } else {
                axios.post(`${props.url}/insert_stock/${auth.id}_stock`, { date: date, particulars: element.prooutput_id, rate: element.rate, quaty: element.quaty, unit: element.unit, total: element.Amout, hotel_id: auth.id, bill_id: `output-${bosNo}`, voucher: "output" })
            }
            // axios.put(`${props.url}/stock/${element.stock_id}`, { date: date, particulars:element.prooutput_id, rate: element.rate, quaty: element.quaty,unit:element.unit,total: element.Amout, hotel_id: auth.id, bill_id: `output-${bosNo}`, voucher: "output" })
        });
        toast.success("Update successfully");
    }
    const closeInput = (index) => {
        let users = input;
        users = users.filter((f) =>
            f == users[index] ? null : f
        )
        setInput(users)
    }
    const deleteinput = async (id) => {
        let res=await axios.delete(`${props.url}/deletestock_item/${auth.id}_stock/${id}`);
        if(res.data){
            setReloadpage(!reloadpage);
        }
    }

    const closeOutput = (index) => {
        let users = output;
        users = users.filter((f) =>
            f == users[index] ? null : f
        )
        setOutput(users)
    }
    const deleteoutput = async (id) => {
        // axios.delete(`${props.url}/stock/${id}`);
        let res=await axios.delete(`${props.url}/deletestock_item/${auth.id}_stock/${id}`);
        if(res.data){
            setReloadpage(!reloadpage);
        }
    }
    var date = "";
    var setup_date = "";
    if (!datelocal.includes('/') && datelocal != "") {
        var date = new Date(datelocal);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
        setup_date = yyyy + "-" + mm + "-" + dd;

    } else if (datelocal.includes('/')) {
        date = datelocal;
    }
    else {
        var date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
        setup_date = yyyy + "-" + mm + "-" + dd;
    }
    const addnewparticulars = () => {
        setReloadpage(!reloadpage);
    }
    const dateisnotreq = () => {
        var date = new Date(auth.date);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }
    return (
        <div className='row'>
            <div className='col-md-12'>
                <div className='row m-3'>
                    <div className='text-center'>
                        <h1>{auth.hotel_name}</h1>
                    </div>
                    <div className='col-2'>
                        <span className='fw-bold'>BOSNO:{bosNo}</span>
                    </div>
                    <div className='col-10 text-end'>
                        <span className='fw-bold'>Date:<input type='date' onChange={(e) => setDatelocal(e.target.value)} min={dateisnotreq()} value={setup_date} /></span>
                    </div>
                    <h6 className='text-center text-uppercase border border-dark bg-dark text-white'>Input</h6>
                    <div className='m-2'>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name of item</th>
                                        <th className='text-center'>Quantity</th>
                                        <th className='text-center'>Rate</th>
                                        <th className='text-center'>Unit</th>
                                        <th className='text-center'>Amount</th>
                                        <th className='text-end'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select onChange={(e) => InputParRate(e.target.value)}>
                                                <option value="">--Select--</option>
                                                {data && data.map((debdata, index) => (
                                                    <option key={index} value={debdata.name}>{debdata.name}</option>
                                                ))}
                                            </select>
                                            <AddPro url={props.url} onupdatepro={addnewparticulars} />
                                        </td>
                                        <td className='text-center'>
                                            {/* <select onChange={(e) => InputAmout(e.target.value)}>
                                                <option value={0}>--Select--</option>
                                                {inputqty && inputqty.map((qty, index) => (
                                                    <option key={index} value={qty}>{qty}</option>
                                                ))}
                                            </select> */}
                                            <input type="number" onChange={(e) => InputAmout(e.target.value)} value={inputquaty}></input>
                                        </td>
                                        <td className='text-center'>
                                            <input value={inputrate} disabled />
                                        </td>
                                        <td className='text-center'>
                                            <input type='text' value={inputunits} disabled />
                                        </td>
                                        <td className='text-end'>
                                            <input value={inputAmout} disabled />
                                        </td>
                                    </tr>
                                    {input && input.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.particular}</td>
                                            <td className='text-center'>{item.quaty}</td>
                                            <td className='text-center'>{item.rate}</td>
                                            <td className='text-center'>{item.unit}</td>
                                            <td className='text-center'>{item.Amout}</td>
                                            <td className='text-end'>
                                                {item.stock_id ? (
                                                    <button className='btn-close' onClick={() => deleteinput(item.stock_id)}></button>
                                                ) : (
                                                    <button className='btn-close' onClick={() => closeInput(index)}></button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={NewInput} className='btn btn-primary'>+</button>
                            <hr />
                            <table className='table'>
                                <tr>
                                    <th>Total Quantity: {totalqut} PCS</th>
                                    <th className='text-end'>Total: &#8377;{total}</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row m-3'>
                    <h6 className='text-center text-uppercase border border-dark bg-dark text-white'>Output</h6>
                    <div className='m-2'>
                        <div className='table-responsive'>
                            <table className='table table-sm'>
                                <thead>
                                    <tr>
                                        <th>Name of item</th>
                                        <th className='text-center'>Quantity</th>
                                        <th className='text-center'>Rate</th>
                                        <th className='text-center'>Unit</th>
                                        <th className='text-center'>Amount</th>
                                        <th className='text-end'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select onChange={(e) => OutputParRate(e.target.value)}>
                                                <option value="">--Select--</option>
                                                {data && data.map((debdata, index) => (
                                                    <option key={index} value={debdata.name}>{debdata.name}</option>
                                                ))}
                                            </select>
                                            <AddPro url={props.url} onupdatepro={addnewparticulars} />
                                        </td>
                                        <td className='text-center'>
                                            {/* <select onChange={(e) => OutputAmout(e.target.value)}>
                                                <option value={0}>--Select--</option>
                                                {outputqty && outputqty.map((qty, index) => (
                                                    <option key={index} value={qty}>{qty}</option>
                                                ))}
                                            </select> */}
                                            <input type="number" onChange={(e) => OutputAmout(e.target.value)} value={outputquaty} />
                                        </td>
                                        <td className='text-center'>
                                            <input value={outputrate} disabled />
                                        </td>
                                        <td className='text-center'>
                                            <input type='text' value={outputunits} disabled />
                                        </td>
                                        <td className='text-end'>
                                            <input value={outputAmout} disabled />
                                        </td>
                                    </tr>
                                    {output && output.map((nf, index) => (
                                        <tr key={index}>
                                            <td>{nf.particular}</td>
                                            <td className='text-center'>{nf.quaty}</td>
                                            <td className='text-center'>{nf.rate}</td>
                                            <td className='text-center'>{nf.unit}</td>
                                            <td className='text-center'>{nf.Amout}</td>
                                            <td className='text-end'>
                                                {nf.stock_id ? (
                                                    <button className='btn-close' onClick={() => deleteoutput(nf.stock_id)}></button>
                                                ) : (
                                                    <button className='btn-close' onClick={() => closeOutput(index)}></button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={NewOutput} className='btn btn-primary'>+</button>
                            <hr />
                            <table className='table'>
                                <tr>
                                    <th>Total Quantity: {outputqyt} PCS</th>
                                    <th className='text-end'>Total: &#8377;{outputamount}</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                {vocherdata == null ? (
                    <div className='row m-3'>
                        <div className='col-md-2 offset-md-10 text-md-end text-center mb-3'>
                            <button className='btn btn-primary' onClick={Savedata}>SAVE</button>
                        </div>
                    </div>
                ) : (
                    <div className='row'>
                        <div className='col-md-2 offset-md-10 text-md-end text-center mb-3'>
                            <button className='btn btn-success' onClick={Updatedata}>UPDATE</button>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    )
}

export default StockJournal