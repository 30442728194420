import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import RegisterPrint_invoice from './RegisterPrint_invoice';
function PurchaseRegister(props) {
    const printref = useRef();
    const { auth } = useUser();
    const [purchaseData, setPurchaseData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [total, setTotal] = useState(0);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch purchase data
                const purchaseResponse = await axios.get(`${props.url}/purchase/${auth.id}_s_p_cn_dn`);
                let sortedPurchaseData = sortDataByDate(purchaseResponse.data);
                const updatedPurchaseDataPromises = sortedPurchaseData.map(async (purchaseItem) => {
                    try {
                        const ledgerResponseCr = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${purchaseItem.ledger_cr}`);
                        const ledgerDataCr = ledgerResponseCr.data;
                        const ledgerResponseDr = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${purchaseItem.ledger_dr}`);
                        const ledgerDataDr = ledgerResponseDr.data;
                        return {
                            ...purchaseItem,
                            ledger_cr: ledgerDataCr.name,
                            ledger_dr: ledgerDataDr.name
                        };
                    } catch (ledgerError) {
                        console.error(`Failed to fetch ledger data for ledger_dr ${purchaseItem.ledger_cr}:`, ledgerError);
                        return purchaseItem; // Return the original item if the ledger request fails
                    }
                });
                sortedPurchaseData = await Promise.all(updatedPurchaseDataPromises);
                console.log(sortedPurchaseData);
                // setPurchaseData(sortedPurchaseData);
                // setFilterdata(sortedPurchaseData)

                // Fetch ledger data
                const LedgerResponse = await axios.get(`${props.url}/show_ledger_under/${auth.id}_ledger/purchase`);
                const LedgerData = LedgerResponse && LedgerResponse.data;

                const journaldata = [];

                for (const ledger of LedgerData) {
                    try {
                        const journalResponse = await axios.get(`${props.url}/journal_ledger/${auth.id}_journal/${ledger.id}`);
                        const sortedJournalData = journalResponse.data;

                        for (const element of sortedJournalData) {
                            try {
                                let journalTotal;

                                // Parse the 'total' JSON string safely
                                try {
                                    journalTotal = JSON.parse(element.total);
                                } catch (error) {
                                    console.error('Failed to parse total JSON:', error);
                                    continue; // Skip this element if parsing fails
                                }

                                // Check if ledger.id exists in journalTotal keys
                                if (Object.keys(journalTotal).includes(`${ledger.id}`)) {
                                    const total = (journalTotal[ledger.id].credit || 0) - (journalTotal[ledger.id].debit || 0);

                                    // Fetch ledger_dr name
                                    const ledgerDrResponse = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${element.ledger_dr}`);
                                    const ledgerDrData = ledgerDrResponse.data;

                                    // Fetch ledger_cr name
                                    const ledgerCrResponse = await axios.get(`${props.url}/show_ledger/${auth.id}_ledger/${element.ledger_cr}`);
                                    const ledgerCrData = ledgerCrResponse.data;

                                    // Create the processed element with the calculated total and updated ledger names
                                    const processedElement = {
                                        ...element,
                                        total: total,
                                        ledger_dr: ledgerDrData.name,
                                        ledger_cr: ledgerCrData.name
                                    };

                                    // Push the processed element into journaldata
                                    journaldata.push(processedElement);
                                }
                            } catch (elementError) {
                                console.error(`Failed to process journal entry for ledger ${ledger.id}:`, elementError);
                            }
                        }
                    } catch (journalError) {
                        console.error(`Failed to fetch journal data for ledger ${ledger.id}:`, journalError);
                    }
                }

                // Merge journal data into existing purchase data using spread operator
                setPurchaseData([...sortedPurchaseData, ...journaldata]);
                setFilterdata([...sortedPurchaseData, ...journaldata]);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.url, auth.id]); // Added props.url and auth.id to dependency array
    const sortDataByDate = (data) => {
        return data.sort((a, b) => {
            const dateA = a.date.split('/').reverse().join('');
            const dateB = b.date.split('/').reverse().join('');
            return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
        });
    };
    useEffect(() => {
        filterData();
    }, [startDate, endDate]); // Re-run filterData whenever startDate or endDate changes

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filterData = () => {
        const filtered = filterdata.filter(item => {
            let datelocal = item.date.split('/').reverse().join('-');
            const itemDate = new Date(datelocal);
            const rangeStartDate = startDate ? new Date(startDate) : null;
            const rangeEndDate = endDate ? new Date(endDate) : null;

            if (rangeStartDate && itemDate < rangeStartDate) {
                return false;
            }

            if (rangeEndDate && itemDate > rangeEndDate) {
                return false;
            }

            return true;
        });

        setPurchaseData(filtered); // Update filteredData state, not purchaseData
    };
    useEffect(() => {
        let total = 0;
        purchaseData.forEach((data) => {
            total += parseInt(data.total);
        })
        setTotal(total);
    }, [purchaseData])
    return (
        <div className='container'>
            <GlobalKeyListener />
            <div className='row mb-4'>
                <div className='col-md-6'>
                    <h1 className='text-decoration-underline'>List Of All Purchase Voucher</h1>
                </div>
                <div className='col-md-6 mt-3'>
                    <input type='date' value={startDate} onChange={handleStartDateChange} />
                    <label>to</label>
                    <input type='date' value={endDate} onChange={handleEndDateChange} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Vch No</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchaseData && purchaseData.map((data) =>
                                    <tr>
                                        <th scope="row">{data.date}</th>
                                        <td>{data.ledger_dr != "Purchase" ? data.ledger_dr : data.ledger_cr}</td>
                                        <td>{data.bos_no}</td>
                                        <td>{data.total}</td>
                                        <td>
                                            <Link className='btn btn-primary' to={`/purchase/${data.slug}`}><i className='bi bi-pen'></i></Link>&nbsp;
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td className='fw-bold'>&#8377;{total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='row'>
                <div className='text-center mt-2 '>
                    <button className='btn btn-primary' onClick={printbill} id="printfun"><i class="bi bi-printer"></i> Print</button>
                </div>
            </div>
            <div className='row d-none' >
                <div className='text-center' ref={printref}>
                    <RegisterPrint_invoice saledata={purchaseData} total={total} register={"Purchase"} filterdate={startDate && endDate ? `${startDate} to ${endDate}` : null} />
                </div>
            </div>
        </div>
    );
}

export default PurchaseRegister;
