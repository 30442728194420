import axios from 'axios';
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const GlobalData = createContext();
import MyPro from './MyPro';
import Ledger from './Ledger';
import Sale from './Sale/Sale';
import PaymentIN from './Receipt/PaymentIN';
import Purchase from './Purchase/Purchase';
import PaymentOut from './Payment/PaymentOut';
import CreditNote from './CreditNote/CreditNote';
import DebitNote from './DebitNote/DebitNote';
import Setting from './Setting';
import Journal from './Journal';
import Profile from './Profile';
import StockJournal from './StockJournal';
import Mainpage from './Mainpage';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import './Dashboard.css';
function Dashboard(props) {
    const [url, setUrl] = useState(`${props.url}`);
    const { auth, Authclear, reloadpage } = useUser();
    const [myComponent, setMyComponent] = useState("");
    const [profileupdate, setProfileUpdate] = useState(0);
    // const setting = JSON.parse(localStorage.getItem("setting"));
    const [setting_bosno, setSetting_Bosno] = useState(auth && auth.bosno ? auth.bosno : "automatic");
    const [settingcontrol, setSettingControl] = useState(true);
    // const [discount, setDiscount] = useState(auth && auth.discount ? auth.discount : "no");
    const [invoicechanger, setInvoiceChanger] = useState(auth && auth.invoice ? auth.invoice : "invoice-1");
    const redirect = useNavigate();
    const navButton = useRef(null);
    const linksContainerRef = useRef(null);
    const [activeLink, setActiveLink] = useState("");
    const [invoicedescription, setInvoiceDescription] = useState(auth && auth.invoicedescription
        ? auth.invoicedescription
        : "");
    useEffect(() => {
        reloadpage();
        setMyComponent("");
    }, [profileupdate, useUser])
    const logout = () => {
        localStorage.clear();
        Authclear();
        redirect("/");
    }
    const SettingSetup = () => {
        let data = {
            frm_name: auth.frm_name,
            fssai: auth.fssai,
            udyam: auth.udyam,
            mobile: auth.mobile,
            address: auth.address,
            email: auth.email,
            password: auth.password,
            logo: auth.logo,
            date: auth.date,
            bosno: setting_bosno,
            invoice: invoicechanger,
            invoicedescription: invoicedescription
        }
        axios.put(`${url}/userupdate/${auth.id}`, data).then((res) => {
            if (res.data) {
                localStorage.setItem("setting", JSON.stringify({
                    bosno: setting_bosno,
                    invoice: invoicechanger,
                    invoicedescription: invoicedescription
                }));
                setSettingControl(!settingcontrol)
            }
        })

    }
    function setNavbar(val) {
        setMyComponent(val)
        navButton.current.classList.add("collapsed");
        linksContainerRef.current.classList.remove("show");
    }
    const handleNavClick = (navItem) => {
        setActiveLink(navItem);
        setNavbar(navItem); // Assuming setNavbar is a function passed as a prop or defined elsewhere
    };

    console.log(auth);

    return (
        <div>
            <GlobalKeyListener />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12  bg-light vh-100 border  '>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <img src={`${props.url}/upload/${auth && auth.logo}`} height={"50px"} alt="Logo"></img>
                                {/* <Link className="navbar-brand" to="#">{auth && auth.hotel_name}</Link> */}
                                <Link className="navbar-brand " onClick={() => handleNavClick("")}>{auth && auth.frm_name}</Link>
                                <button ref={navButton} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div ref={linksContainerRef} className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("")}
                                            >
                                                Dashboard
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "sale" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("sale")}
                                            >
                                                Sale
                                            </button>
                                        </li>
                                        {/* <li className="nav-item d-flex">
      <button
        className={`nav-link btn custom-btn ${activeLink === "ledger" ? "active text-primary fw-bold" : ""}`}
        onClick={() => handleNavClick("ledger")}
      >
        Ledger
      </button>
    </li> */}
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "paymentin" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("paymentin")}
                                            >
                                                Receipt
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "purchase" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("purchase")}
                                            >
                                                Purchase
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "paymentout" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("paymentout")}
                                            >
                                                Payment
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "creditnote" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("creditnote")}
                                            >
                                                Credit Note
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "debitnote" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("debitnote")}
                                            >
                                                Debit Note
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex d-md-inline d-none">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "journal" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("journal")}
                                            >
                                                Journal
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "inventory" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("inventory")}
                                            >
                                                Inventory
                                            </button>
                                        </li>
                                        <li className="nav-item d-flex d-md-inline d-none">
                                            <button
                                                className={`nav-link btn custom-btn ${activeLink === "stock-journal" ? "active text-primary fw-bold" : ""}`}
                                                onClick={() => handleNavClick("stock-journal")}
                                            >
                                                Stock Journal
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="nav-item d-flex">
                                        <div className='me-2'>
                                            <button className="nav-link" data-bs-toggle="modal" data-bs-target="#setting"><i className="bi bi-gear">&nbsp;</i>Settings</button>
                                        </div>
                                        <div className='me-2'>
                                            <Link className="nav-link" to={"/"} onClick={logout}><i className="bi bi-box-arrow-right"></i>&nbsp;Logout</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className='container-fluid bg-white rounded shadow '>

                            {
                                myComponent == "ledger" ? <Ledger url={url} /> : myComponent == "sale" ? <Sale url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "paymentin" ? <PaymentIN url={url} setting={settingcontrol} /> : myComponent == 'purchase' ? <Purchase url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == 'paymentout' ? <PaymentOut url={url} /> : myComponent == 'creditnote' ? <CreditNote url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "debitnote" ? <DebitNote url={url} bosno={setting_bosno} setting={settingcontrol} /> : myComponent == "journal" ? <Journal url={url} /> : myComponent == "inventory" ? <MyPro url={url} /> : myComponent == "stock-journal" ? <StockJournal url={url} /> :
                                    <Mainpage url={url} />
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div class="modal fade" id="setting" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-4 row">
                                <label class="col-sm-4 col-form-label">Bill Of Supply</label>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="bosno" id="Automatic" checked={setting_bosno == "automatic" ? true : false} value={"automatic"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                        <label class="form-check-label" for="Automatic">
                                            Automatic
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="bosno" id="Manual" checked={setting_bosno == "manual" ? true : false} value={"manual"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                        <label class="form-check-label" for="Manual">
                                            Manual
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='mb-4 row'>
                                <label class="col-sm-4 col-form-label">Use Discount column in invoice</label>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="discount" id="Automatic" checked={discount == "yes" ? true : false} value={"yes"} onChange={(e) => setDiscount(e.target.value)}></input>
                                        <label class="form-check-label" for="Automatic">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="discount" id="Manual" checked={discount == "no" ? true : false} value={"no"} onChange={(e) => setDiscount(e.target.value)}></input>
                                        <label class="form-check-label" for="Manual">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className='mb-4 row '>
                                <div className='col-12 mt-1'>
                                    <input type="radio" className="btn-check" id="invoice-1" name='invoice' value={"invoice-1"} autoComplete="off" checked={invoicechanger == "invoice-1" ? true : false} onChange={(e) => setInvoiceChanger(e.target.value)}></input>
                                    <label className="btn btn-primary w-100" htmlFor="invoice-1">
                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-shrink-0">
                                                <img src="..." alt="..."></img>
                                            </div>
                                            Invoice-1
                                        </div>
                                    </label>
                                </div>
                                <div className='col-12 mt-1'>
                                    <input type="radio" className="btn-check" id="invoice-2" name='invoice' value={"invoice-2"} autoComplete="off" checked={invoicechanger == "invoice-2" ? true : false} onChange={(e) => setInvoiceChanger(e.target.value)}></input>
                                    <label className="btn btn-primary w-100" htmlFor="invoice-2">
                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-shrink-0">
                                                <img src="..." alt="..."></img>
                                            </div>
                                            Invoice-2
                                        </div>
                                    </label>
                                </div>
                                <div className='col-12 mt-1'>
                                    <label>Invoice Description</label>
                                    <textarea className='form-control' onChange={(e) => setInvoiceDescription(e.target.value)} >{invoicedescription}</textarea>
                                </div>
                                {/* <div className='col-12 mt-1'>
                                    <input type="radio" className="btn-check" id="invoice-3" name='invoice' value={"invoice-3"} autoComplete="off" checked={invoicechanger=="invoice-3"?true:false} onChange={(e)=>setInvoiceChanger(e.target.value)}></input>
                                    <label className="btn btn-primary w-100" htmlFor="invoice-3">
                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-shrink-0">
                                                <img src="..." alt="..."></img>
                                            </div>
                                            Invoice-3
                                        </div>
                                    </label>
                                </div> */}

                            </div>


                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={SettingSetup}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </div>
    )
}

export default Dashboard
